import { Td, Tr, Table as ChakraTable, Thead, useDisclosure } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useTranslation } from 'utils/translation';
import { CompanyFinancialResults } from '../../Financials.hooks';
import React from 'react';
import { allFinancialSections } from '../../../../utils/financials';
import { GetFinancialsDocumentationByIdDocument_ } from 'models';
import { StateStatus } from 'Molecules/CardStatus';
import { InputCard } from 'Molecules/InputCard';
import { TruncatableText } from 'Atoms/TruncatableText';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import { useGetBusinessUnitTableData } from './FinancialTables.hooks.ts';
import { FinancialTableColumnHeader } from './TableComponents/FinancialColumnHeader.tsx';
import { FinancialInput } from './TableComponents/FinancialInput.tsx';
import { FinancialTableFooter } from './TableComponents/FinancialTableFooter.tsx';

type FinancialTableProps = {
  businessUnit: CompanyFinancialResults['businessUnits'][number];
};

export const BusinessUnitFinancialTable = ({ businessUnit }: FinancialTableProps) => {
  const { t } = useTranslation(['common', 'bUnits']);

  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();

  const {
    documentation,
    isLocked,
    currency,
    editableFinancials,
    eligible,
    total,
    isEstimate,
    toggleEstimateStatus,
  } = useGetBusinessUnitTableData({
    businessUnit,
    onAttachmentDrawerOpen,
  });

  console.log('isEstimate', isEstimate);
  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      refetch={[GetFinancialsDocumentationByIdDocument_]}
      onClose={onAttachmentDrawerClose}
      attachmentBox={documentation.attachmentBox}
    >
      <InputCard
        documentation={documentation.documentation}
        header={{
          title: businessUnit.businessUnit?.name ?? 'NA',
          status: isEstimate ? StateStatus.todo : StateStatus.done,
          actions: isLocked
            ? []
            : [
                <Button
                  key={businessUnit.id + 'mark-as-done'}
                  variant="ghost"
                  size="md"
                  onClick={() => {
                    toggleEstimateStatus(editableFinancials?.map((f) => f.id) ?? [], isEstimate);
                  }}
                >
                  {isEstimate ? t('common:actions.done') : t('common:actions.edit')}
                </Button>,
              ],
        }}
        {...{ minWidth: '800px' }}
        loading={total === undefined}
      >
        <ChakraTable>
          <Thead>
            <FinancialTableColumnHeader title={t('common:activity')} />
            {allFinancialSections.map((section) => (
              <FinancialTableColumnHeader
                key={section}
                title={t(
                  `common:financials.${section}${
                    section.includes('adapt') ? t('common:words.short') : ''
                  }`
                )}
                helpLabel={t(`common:financials.${section}Tooltip`)}
                caption={currency}
              />
            ))}
          </Thead>
          {editableFinancials?.map((item) => (
            <Tr key={item?.id}>
              <Td>
                <TruncatableText text={item?.name ?? ''} variant="body" />
              </Td>
              {allFinancialSections.map((section) => {
                return (
                  <Td key={`${item?.id}-${section}`} padding="8px" textAlign="right">
                    <FinancialInput
                      key={`${item?.id}-${section}-input`}
                      section={section}
                      financialsId={item?.id}
                    />
                  </Td>
                );
              })}
            </Tr>
          ))}
          <FinancialTableFooter
            isTotal
            title={t('bUnits:financials.total')}
            financials={total}
            helpLabel={t('common:financials.totalTooltip')}
          />
          <FinancialTableFooter
            title={t('bUnits:financials.notEligible')}
            financials={editableFinancials?.find(
              (act) => act.name === 'Taxonomy-non-eligible activities'
            )}
            helpLabel={t('common:financials.notEligibleTooltip')}
          />
          <FinancialTableFooter
            title={t('bUnits:financials.eligible')}
            financials={eligible}
            helpLabel={t('common:financials.eligibleTooltip')}
          />
        </ChakraTable>
      </InputCard>
    </AttachmentDrawer>
  );
};
