import { VStack } from '@chakra-ui/react';
import { Modal } from 'Molecules';
import { DrLearnMore } from './DrLearnMore';
import { AssessDisclosureRequirement } from './AssessDisclosureRequirement';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MetricsMaterialityTable } from './MetricsMaterialityTable';
import {
  MappedDisclosureRequirements,
  MaterialityFields,
  MaterialityState,
  ParentMaterialMetrics,
  ParentMaterialityState,
  StandardMaterialityState,
} from '../DoubleMaterialityAssessment.d';
import { updateAllMateriality, useSaveDrMetrics } from './DrMaterialityModal.hooks';
import { MaterialMetric } from '../../MaterialityAssessment.d';
import { ParentInfoBox } from '../MaterialityUtils';
import { DisclosureRequirementInfo } from './DrMaterialityModalUtils';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';

export const DrMaterialityModal = ({
  isOpen,
  hasParent,
  standardMateriality,
  drMaterialMetrics,
  isGroup,
  isCollectOnly,
  isStandardMandatory,
  parentMetrics,
  materialStandardId,
  disclosureRequirement,
  onClose,
}: {
  isOpen: boolean;
  hasParent: boolean;
  isCollectOnly: boolean;
  drMaterialMetrics: MaterialMetric[];
  isGroup: boolean;
  parentMetrics: ParentMaterialMetrics;
  standardMateriality: StandardMaterialityState;
  isStandardMandatory: boolean;
  materialStandardId: string;
  disclosureRequirement?: MappedDisclosureRequirements[number];
  onClose: () => void;
}) => {
  const [materialMetrics, setMaterialMetrics] = useState<MaterialMetric[]>(drMaterialMetrics);
  const saveMetrics = useSaveDrMetrics();
  const { control, watch, setValue } = useForm<MaterialityFields>();

  useEffect(() => {
    setValue('material', disclosureRequirement?.materialityStatus ?? MaterialityState.toAssess);
  }, [disclosureRequirement]);

  const materialValue = useMemo(() => watch('material'), [watch('material')]);

  const isDRAssessed = useMemo(() => materialValue !== MaterialityState.toAssess, [materialValue]);

  const updateAllMetrics = (materiality: MaterialityState | null) => {
    const updatedMetrics = updateAllMateriality(
      disclosureRequirement?.metrics ?? [],
      materiality,
      drMaterialMetrics
    );
    setMaterialMetrics(updatedMetrics);
  };

  const handleConfirm = () => {
    saveMetrics(materialMetrics, isGroup, materialStandardId, disclosureRequirement?.drRef);
    onClose();
  };

  const drMateriality = useMemo(
    () => disclosureRequirement?.materialityStatus,
    [disclosureRequirement]
  );
  const status = useMemo(() => watch('material') ?? MaterialityState.toAssess, [watch('material')]);

  const isMandatoryOrMaterialMandatory = useMemo(() => {
    if (status === MaterialityState.mandatory || status === MaterialityState.materialMandatory) {
      return true;
    }
    if (isCollectOnly) {
      return isStandardMandatory;
    }
    return false;
  }, [status, isCollectOnly, isStandardMandatory]);

  const canCollectDisclosure = useMemo(
    () =>
      drMateriality === MaterialityState.collectData ||
      drMateriality === MaterialityState.doNotCollect,
    [drMateriality]
  );

  const showTable = useMemo(
    () =>
      isMandatoryOrMaterialMandatory ||
      (isCollectOnly && materialValue !== MaterialityState.doNotCollect) ||
      (!isCollectOnly && materialValue !== MaterialityState.notMaterial) ||
      !isDRAssessed,
    [materialValue, isDRAssessed, isMandatoryOrMaterialMandatory]
  );

  const parentDrMetrics = useMemo(
    () =>
      parentMetrics.filter(
        (m) => m.metric.disclosureRequirementRef === disclosureRequirement?.drRef
      ),
    [parentMetrics]
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={disclosureRequirement?.title}
      size="xl"
      subtitle={'Disclosure requirement ' + disclosureRequirement?.drRef}
      onConfirm={handleConfirm}
      onCancel={onClose}
      hasFooter={
        status !== MaterialityState.mandatory && status !== MaterialityState.materialMandatory
      }
    >
      <VStack spacing="16px" alignItems="strech" width="100%">
        <VStack alignItems="strech" gap="8px">
          {disclosureRequirement?.parentMateriality &&
            hasParent &&
            status !== MaterialityState.mandatory &&
            !isCollectOnly && (
              <ParentInfoBox
                status={disclosureRequirement?.parentMateriality as ParentMaterialityState}
              />
            )}
          <DisclosureRequirementInfo status={status} />
        </VStack>
        <VStack spacing="0px" alignItems="stretch">
          <DrLearnMore
            disclosureRequirement={disclosureRequirement}
            isCollectOnly={isCollectOnly}
            isStandardMandatory={isStandardMandatory}
          />
          {!isMandatoryOrMaterialMandatory && (isCollectOnly ? canCollectDisclosure : true) && (
            <AssessDisclosureRequirement
              disableMaterial={standardMateriality === StandardMaterialityState.gatherData}
              control={control}
              showTable={showTable}
              disclosureRequirement={disclosureRequirement}
              isCollectOnly={isCollectOnly}
              isStandardMandatory={isStandardMandatory}
              updateAllMateriality={updateAllMetrics}
              isParentMaterial={parentDrMetrics.some(
                (m) =>
                  m.isMaterial &&
                  m.dataCollection === DataCollectionLevel.subsidiaries &&
                  m.metric.childrenMetrics_aggregate.aggregate?.count === 0
              )}
            />
          )}
          {showTable && (
            <MetricsMaterialityTable
              readOnly={isMandatoryOrMaterialMandatory}
              disclosureRequirement={disclosureRequirement}
              drMateriality={materialValue}
              setMaterialMetrics={setMaterialMetrics}
              materialMetrics={materialMetrics}
              hasParent={hasParent}
              parentMetrics={parentMetrics}
              isCollectOnly={isCollectOnly}
              isGroup={isGroup}
            />
          )}
        </VStack>
      </VStack>
    </Modal>
  );
};
