import { TAG_STATUS } from 'Molecules/ObjectivesTiles';
import { Typography } from 'Tokens';
import { useKnowledgeBase } from 'Features';
import { useTranslation } from 'utils/translation';
import { objectiveExpressionTag } from './Activities.hooks';
import { ARTICLE_ENABLING_TRANSITIONAL_EXCLUSIONS } from 'Features/KnowledgeBase/KnowledgeBaseArticles';

export const ObjectiveDescription = ({ tagExpression }: { tagExpression: string | undefined }) => {
  const { t } = useTranslation('assessment');
  const tagExpressionValue = objectiveExpressionTag(tagExpression);
  const { onOpen } = useKnowledgeBase();

  const renderObjectiveDescription = (textKey: string, slug: string) => (
    <Typography variant="body" color="text.default">
      {t(textKey)}{' '}
      <Typography as="span" onClick={() => onOpen(slug)} color="text.selected" cursor="pointer">
        {t('assessment:activities.disclaimer.readMore')}
      </Typography>
    </Typography>
  );

  const isEnabling =
    tagExpressionValue === TAG_STATUS.Enabling.status ||
    tagExpressionValue === TAG_STATUS.CanBeEnabling.status;

  const isTransitional =
    tagExpressionValue === TAG_STATUS.Transitional.status ||
    tagExpressionValue === TAG_STATUS.CanBeTransitional.status;

  return isEnabling
    ? renderObjectiveDescription(
        'assessment:activities.disclaimer.enabling',
        ARTICLE_ENABLING_TRANSITIONAL_EXCLUSIONS.slug
      )
    : isTransitional
      ? renderObjectiveDescription(
          'assessment:activities.disclaimer.transitional',
          ARTICLE_ENABLING_TRANSITIONAL_EXCLUSIONS.slug
        )
      : null;
};
