import { Thead, Tr, Td, Table as ChakraTable } from '@chakra-ui/react';
import { Button, TruncatableText } from 'Atoms/index.ts';
import { StateStatus } from 'Molecules/CardStatus/CardStatus.tsx';
import { InputCard } from 'Molecules/InputCard/InputCard.tsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { scoreSections } from 'utils/financials.ts';
import { FinancialTableColumnHeader } from './TableComponents/FinancialColumnHeader.tsx';
import { FinancialInput } from './TableComponents/FinancialInput.tsx';
import { useGetCompanyNotEligibleTableData } from './FinancialTables.hooks.ts';

export const CompanyNotEligibleTable = () => {
  const { t } = useTranslation(['common', 'bUnits']);
  const { financials, currency, isLocked, toggleEstimateStatus } =
    useGetCompanyNotEligibleTableData();

  return (
    <InputCard
      header={{
        title: t('bUnits:financials.companyOther'),
        status: financials?.isEstimate ? StateStatus.todo : StateStatus.done,
        actions: isLocked
          ? []
          : [
              <Button
                key={financials?.id + 'mark-as-done'}
                variant="ghost"
                size="md"
                onClick={() => {
                  toggleEstimateStatus([financials?.id], financials?.isEstimate ?? false);
                }}
              >
                {financials?.isEstimate ? t('common:actions.done') : t('common:actions.edit')}
              </Button>,
            ],
      }}
      {...{ minWidth: '800px' }}
    >
      <ChakraTable>
        <Thead>
          <FinancialTableColumnHeader title={t('common:activity')} />
          {scoreSections.map((section) => (
            <FinancialTableColumnHeader
              title={t(`common:financials.${section}`)}
              helpLabel={t(`common:financials.${section}Tooltip`)}
              caption={currency}
            />
          ))}
        </Thead>
        <Tr>
          <Td>
            <TruncatableText text={t('bUnits:financials.other')} variant="body" />
          </Td>
          {scoreSections.map((section) => {
            return (
              <Td key={section} padding="8px" textAlign="right">
                <FinancialInput section={section} financialsId={financials?.id} />
              </Td>
            );
          })}
        </Tr>
      </ChakraTable>
    </InputCard>
  );
};
