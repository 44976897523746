import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { Button, Infobox } from 'Atoms';
import { SelectedMetric, useAssessmentReportingUnits } from 'containers/Esrs';
import {
  DisclosureRequirementStatusDocument_,
  useDisclosureRequirementStatusQuery,
  useGetEsrsStandardHeaderQuery,
  useUpsertDisclosureRequirementStatusMutation,
} from 'models';
import { ContentLayout, LearnMoreDrawer, MetricLearnMoreHeader } from 'Molecules';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import {
  MetricPerDisclosureMaybeHasChild,
  hasCompletedTags,
  useMaterialMetricsPerDR,
  useMaterialStandardDataCollection,
  metricsWithRequiredTags,
} from './DataCollection.hooks';
import { DisclosureRequirementMetricsTable } from './DisclosureRequirementMetricsTable/DisclosureRequirementMetricsTable';
import { DataCollectionLevel, MaterialMetricPerDisclosure, TableMetricData } from '.';
import { useTranslation } from 'utils/translation';
import { DataCollectionSetupStatusTag } from './DataCollectionSetup';
import { StepWrapper } from 'Molecules/StepWrapper';
import { StatusAccordion } from 'Molecules/StatusAccordion';
import { CheckIcon } from 'Tokens/Icons/Status';
import { useCompanyType } from 'utils/hooks';

const checkDataGatheringLevel = (
  nonAggregatableMetrics: MaterialMetricPerDisclosure[],
  dataCollectionLevel: string
): boolean => {
  return nonAggregatableMetrics?.some((metric) =>
    metric.materialMetrics?.some(
      (materialMetric) => materialMetric.dataCollection === dataCollectionLevel
    )
  );
};

const WarningAlert = ({ header, message }: { header: string; message: string }) => {
  return <Infobox status="warning" title={header} description={message} closable={false} />;
};

const NonAggregatableMetricAlert = ({ metrics }: { metrics: MaterialMetricPerDisclosure[] }) => {
  const { isGroup } = useCompanyType();
  const { t } = useTranslation('esrs');

  const dataCollectionLevel = useMemo(
    () => (isGroup ? DataCollectionLevel.subsidiaries : DataCollectionLevel.reportingUnits),
    [isGroup]
  );
  const warningMessage = useMemo(
    () =>
      isGroup
        ? t('assessment.nonAggregatableAlert.groupMetricsMessage')
        : t('assessment.nonAggregatableAlert.companyMetricsMessage'),
    [isGroup]
  );

  const nonAggregatableMetrics = useMemo(
    () => metrics.filter((metric) => metric.notAggregatable),
    [metrics]
  );

  const checkGatheringLevel = useMemo(
    () => checkDataGatheringLevel(nonAggregatableMetrics, dataCollectionLevel),
    [nonAggregatableMetrics, dataCollectionLevel]
  );

  if (checkGatheringLevel) {
    return (
      <WarningAlert header={t('assessment.nonAggregatableAlert.header')} message={warningMessage} />
    );
  }
  return null;
};

export const StandardMetricsDataCollection = () => {
  const { disclosureRequirementRef = '', standardRef = '', esrsAssessmentId = '' } = useParams();
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  const navigate = useNavigate();

  const { companyStandardId, parentStandardId, parentEsrsAssessmentId } =
    useMaterialStandardDataCollection(standardRef, esrsAssessmentId);

  const { data: disclosureRequirement } = useDisclosureRequirementStatusQuery({
    variables: {
      disclosureRequirementRef: disclosureRequirementRef,
      materialStandardId: companyStandardId,
    },
    skip: !disclosureRequirementRef || !companyStandardId,
  });

  const { isGroup } = useCompanyType();

  const { data: standardData } = useGetEsrsStandardHeaderQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });
  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);

  const { disclosureMetricData, metricDataLoading } = useMaterialMetricsPerDR({
    disclosureRequirementRef,
    companyStandardId,
    parentStandardId,
    isGroup,
    isStandardMandatory: !(standard?.isTopical ?? true),
  });

  const [metrics, requiredMetrics, voluntaryMetrics] = useMemo(() => {
    const assessableMetrics: MaterialMetricPerDisclosure[] =
      disclosureMetricData?.assessableMetrics ?? [];

    return [
      assessableMetrics,
      metricsWithRequiredTags(assessableMetrics, isGroup),
      assessableMetrics.filter(
        (m) => m.adminPanelTags.length && m.adminPanelTags.every((tag) => tag.isOptional)
      ),
    ];
  }, [disclosureMetricData]);

  const [completedRequiredMetricsCount, completedVoluntaryMetricsCount] = useMemo(
    () => [
      requiredMetrics.filter((met) =>
        hasCompletedTags(met as MetricPerDisclosureMaybeHasChild, true)
      ).length,
      voluntaryMetrics.filter((met) => hasCompletedTags(met as MetricPerDisclosureMaybeHasChild))
        .length,
    ],
    [requiredMetrics, voluntaryMetrics]
  );

  const [upsertDisclosureRequirementStatus] = useUpsertDisclosureRequirementStatusMutation();

  const isDone = useMemo(
    () => disclosureRequirement?.drStatus?.isConfigured,
    [disclosureRequirement]
  );

  const handleMarkAsDone = async () => {
    try {
      await upsertDisclosureRequirementStatus({
        variables: {
          drRef: disclosureRequirementRef,
          isConfigured: true,
          materialStandardId: companyStandardId,
        },
        refetchQueries: [DisclosureRequirementStatusDocument_],
      });

      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const { reportingUnitAssessments: reportingUnits } =
    useAssessmentReportingUnits(esrsAssessmentId);
  const hasReportingUnits = useMemo(
    () => !!reportingUnits?.filter((ru) => ru.isCompanyLevel === false)?.length,
    [reportingUnits]
  );

  const isNonAggregatableMetricPresent = useMemo(
    () => metrics.some((metric) => metric.notAggregatable),
    [metrics]
  );

  const [selectedRows, setSelectedRows] = useState<TableMetricData[]>([]);

  return (
    <VStack h="fit-content" alignItems="center" justifyContent="start" flexGrow="1">
      <VStack w="100%" alignItems="start" flexGrow="1">
        <ContentLayout
          variant="inline.nopad"
          isLoading={metricDataLoading}
          backButton={true}
          header={false}
        >
          <VStack alignItems="stretch" padding="32px 40px" gap="40px">
            <HStack w="100%" justifyContent="space-between" alignItems="start">
              <VStack alignItems="start" gap="4px">
                <Typography variant="h1">{disclosureRequirement?.drInfo?.title}</Typography>
                <Typography variant="body">
                  On this page you will see all required and voluntary data points configurations
                  for this disclosure requirement.
                </Typography>
              </VStack>
              <Box w="130px" display="flex" justifyContent="end">
                <DataCollectionSetupStatusTag
                  isDataCollected={isDone ?? false}
                  helpLabel="Complete all steps on this page to set up data collection for this Disclosure requirement"
                />
              </Box>
            </HStack>
            <VStack gap="0px" alignItems="stretch">
              {(!!requiredMetrics.length || !!voluntaryMetrics.length) && (
                <StepWrapper title="Important to configure">
                  <VStack gap="8px" w="100%">
                    {!!requiredMetrics.length && (
                      <StatusAccordion
                        title="Required disaggregation"
                        subtitle="Metrics that are required by the ESRS to be disaggregated"
                        status={
                          requiredMetrics.length === 0
                            ? 'default'
                            : completedRequiredMetricsCount === requiredMetrics.length
                              ? 'success'
                              : 'warning'
                        }
                        completedNumber={completedRequiredMetricsCount}
                        totalNumber={requiredMetrics.length}
                        content={
                          <DisclosureRequirementMetricsTable
                            isTagsOnly
                            key={disclosureRequirementRef}
                            metrics={requiredMetrics}
                            disclosureRequirementRef={disclosureRequirementRef}
                            disclosureRequirementType={disclosureRequirement?.drInfo?.type ?? ''}
                            setSelectedMetric={setSelectedMetric}
                            onDrawerOpen={onDrawerOpen}
                            parentEsrsAssessmentId={parentEsrsAssessmentId}
                            companyStandardId={companyStandardId}
                            parentStandardId={parentStandardId}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            isStandardMandatory={!(standard?.isTopical ?? true)}
                            hasReportingUnits={hasReportingUnits}
                          />
                        }
                      />
                    )}
                    {!!voluntaryMetrics.length && (
                      <StatusAccordion
                        title="Voluntary disaggregation"
                        subtitle="Metrics that are voluntary to disaggregate"
                        completedNumber={completedVoluntaryMetricsCount}
                        totalNumber={voluntaryMetrics.length}
                        content={
                          <DisclosureRequirementMetricsTable
                            isTagsOnly
                            key={disclosureRequirementRef}
                            metrics={voluntaryMetrics}
                            disclosureRequirementRef={disclosureRequirementRef}
                            disclosureRequirementType={disclosureRequirement?.drInfo?.type ?? ''}
                            setSelectedMetric={setSelectedMetric}
                            onDrawerOpen={onDrawerOpen}
                            parentEsrsAssessmentId={parentEsrsAssessmentId}
                            companyStandardId={companyStandardId}
                            parentStandardId={parentStandardId}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            isStandardMandatory={!(standard?.isTopical ?? true)}
                            hasReportingUnits={hasReportingUnits}
                          />
                        }
                      />
                    )}
                  </VStack>
                </StepWrapper>
              )}
              <StepWrapper
                title="All datapoints"
                subtitle="Here you can see the full list of all data points you've selected to report on within this disclosure requirement, including metrics with required and voluntary disaggregation. You don’t have to change any configurations besides the required ones, but we recommend you to review all data points before proceeding further."
              >
                {isNonAggregatableMetricPresent && (
                  <Box width="100%" mt="16px">
                    <NonAggregatableMetricAlert metrics={metrics} />
                  </Box>
                )}
                <DisclosureRequirementMetricsTable
                  key={disclosureRequirementRef}
                  metrics={metrics}
                  disclosureRequirementRef={disclosureRequirementRef}
                  disclosureRequirementType={disclosureRequirement?.drInfo?.type ?? ''}
                  setSelectedMetric={setSelectedMetric}
                  onDrawerOpen={onDrawerOpen}
                  parentEsrsAssessmentId={parentEsrsAssessmentId}
                  companyStandardId={companyStandardId}
                  parentStandardId={parentStandardId}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  isStandardMandatory={!(standard?.isTopical ?? true)}
                  hasReportingUnits={hasReportingUnits}
                />
              </StepWrapper>
              <StepWrapper
                title="Complete the setup"
                subtitle="Once you are done with all configurations, mark this disclosure requirement configuration as “Done”. Note that it’s not possible to finish the setup without completing the required configurations."
                isLastStep
              >
                <Button
                  variant="primary"
                  size="md"
                  title="Done"
                  isDisabled={requiredMetrics.length > completedRequiredMetricsCount}
                  pointerEvents={isDone ? 'none' : undefined}
                  bg={isDone ? 'bg.selected' : undefined}
                  leftIcon={<CheckIcon color={isDone ? 'text.selected' : 'inherit'} />}
                  onClick={handleMarkAsDone}
                >
                  <Typography variant="bodyStrong" color={isDone ? 'text.selected' : 'inherit'}>
                    Done
                  </Typography>
                </Button>
              </StepWrapper>
            </VStack>
          </VStack>
          <LearnMoreDrawer
            isOpen={isDrawerOpen}
            onClose={onDrawerClose}
            description={selectedMetric?.description}
            customHeader={
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            }
          />
        </ContentLayout>
      </VStack>
    </VStack>
  );
};
