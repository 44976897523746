import { Infobox, InfoboxProps } from 'Atoms/Infobox';
import { useTranslation } from 'utils/translation';
import { useContainsNegativeNumbers } from './FinancialsHeader.hooks.js';
import { VisibleIf } from 'Atoms/VisibleIf/VisibleIf.jsx';

export const NegativeNumbersWarningBox = ({ ...props }: Omit<InfoboxProps, 'status'>) => {
  const { t } = useTranslation('common');
  const containsNegativeFinancials = useContainsNegativeNumbers();

  return (
    <VisibleIf condition={containsNegativeFinancials}>
      <Infobox
        width="100%"
        status="warning"
        closable={false}
        title={t('common:financials.negativeNumbersWarning.title')}
        description={t('common:financials.negativeNumbersWarning.description')}
        {...props}
      />
    </VisibleIf>
  );
};
