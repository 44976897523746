import { Collapse, HStack, VStack } from '@chakra-ui/react';
import { Infobox } from 'Atoms/Infobox/Infobox.tsx';
import { ExternalLink } from 'Atoms/Link/Link.tsx';
import { useKnowledgeBase } from 'Features/KnowledgeBase/KnowledgeBase.tsx';
import {
  ARTICLE_ADAPTATION_CAPEX_OPEX,
  ARTICLE_WHAT_IS_CAPEX,
  ARTICLE_WHAT_IS_OPEX,
  ARTICLE_WHAT_IS_TURNOVER,
} from 'Features/KnowledgeBase/KnowledgeBaseArticles.ts';
import { t } from 'i18next';

export const FinancialsHelpInfoBox = ({
  onClose,
  shouldShowHelp,
}: {
  onClose: () => void;
  shouldShowHelp: boolean;
}) => {
  const { onOpen: openArticle } = useKnowledgeBase();

  return (
    <HStack
      as={Collapse}
      in={shouldShowHelp}
      width="100%"
      justifyContent="stretch"
      marginBottom="14px"
    >
      <Infobox
        title={t('common:assessment.companyFinancials.info.title')}
        description={t('common:assessment.companyFinancials.info.description')}
        status="neutral"
        closable
        onClose={onClose}
        extra={
          <VStack spacing="4px" width="100%" alignItems="flex-start">
            <ExternalLink onClick={() => openArticle(ARTICLE_WHAT_IS_TURNOVER.slug)}>
              {t('common:assessment.companyFinancials.turnover')}
            </ExternalLink>
            <ExternalLink onClick={() => openArticle(ARTICLE_WHAT_IS_CAPEX.slug)}>
              {t('common:assessment.companyFinancials.capex')}
            </ExternalLink>
            <ExternalLink onClick={() => openArticle(ARTICLE_WHAT_IS_OPEX.slug)}>
              {t('common:assessment.companyFinancials.opex')}
            </ExternalLink>
            <ExternalLink onClick={() => openArticle(ARTICLE_ADAPTATION_CAPEX_OPEX.slug)}>
              {t('common:assessment.companyFinancials.adaptation')}
            </ExternalLink>
          </VStack>
        }
      />
    </HStack>
  );
};
