import { aggregateFinancials, FINANCIAL_SECTIONS } from 'utils/financials.ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AttachmentBox,
  Financials,
  GetFinancialsDocumentationByIdDocument_,
  NoteHistory,
  ReportingGroup,
  useGetFinancialsDocumentationByIdQuery,
  useReportingGroupsLazyQuery_,
  useUpdateFinancialEstimateManyMutation,
} from 'models/index.ts';
import { useParams } from 'react-router-dom';
import { FinancialFilter, useGetFilterData } from '../Header/FinancialsHeader.hooks.ts';
import { CompanyFinancialResults, useFinancials } from 'Features/Financials/Financials.hooks.ts';
import { InputCardDocumentationProps } from 'Molecules/InputCard/InputCardDocumentation.tsx';
import { useUserData } from '@nhost/react';
import { useCurrentCompany, useGetCurrentCAssessment } from 'utils/hooks/General.hooks.ts';
import { useDisclosure } from '@chakra-ui/react';

export const MAX_ALLOWED_NUMBER = 900000000000000;
export const MIN_ALLOWED_NUMBER = 0;

export const allFinancialSectionKeys = FINANCIAL_SECTIONS.map((section) => section.key);

const useCommonFinancialData = () => {
  const { financials } = useFinancials({});
  const { isLocked } = useGetCurrentCAssessment();
  const { company } = useCurrentCompany();
  const currency = company?.currency;

  return { financials, isLocked, currency };
};

const useDocumentationData = (financialsId: string, onAttachmentDrawerOpen: () => void) => {
  const user = useUserData();
  const { isLocked } = useGetCurrentCAssessment();
  const { data: documentationData } = useGetFinancialsDocumentationByIdQuery({
    variables: { financialsId },
  });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.Financials_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.Financials_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );

  const documentation: InputCardDocumentationProps = useMemo(
    () => ({
      isHidden: isLocked,
      currentAuthor: user ?? undefined,
      attachmentBox,
      noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetFinancialsDocumentationByIdDocument_],
    }),
    [noteHistory, attachmentBox, user, documentationData]
  );

  return { documentation, attachmentBox };
};

export const useGetFilteredFinancialsData = () => {
  const { filter, filteredById } = useGetFilterData();
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();
  const [reportingGroups, setReportingGroups] = useState<ReportingGroup[]>([]);
  const [getReportingGroupsQuery] = useReportingGroupsLazyQuery_();
  const { financials } = useFinancials({});

  useEffect(() => {
    getReportingGroupsQuery({ variables: { cAssessmentId } }).then((result) =>
      setReportingGroups(result.data?.reportingGroups ?? [])
    );
  }, [cAssessmentId, getReportingGroupsQuery]);

  const filteredFinancials: CompanyFinancialResults['businessUnits'][number] | undefined =
    useMemo(() => {
      if (filter === FinancialFilter.byId) {
        return financials.businessUnits.find((bu) => bu.businessUnit?.id === filteredById);
      }
    }, [filter, filteredById, financials]);

  return { filter, filteredById, reportingGroups, filteredFinancials, financials };
};

export const useToggleEstimateStatus = () => {
  const [updateFinancials] = useUpdateFinancialEstimateManyMutation();

  const toggleEstimateStatus = useCallback(
    async (financialIds: string[], isEstimate: boolean) => {
      await updateFinancials({
        variables: {
          ids: financialIds,
          isEstimate: !isEstimate,
        },
      });
    },
    [updateFinancials]
  );

  return { toggleEstimateStatus };
};

export const useGetBusinessUnitTableData = ({
  businessUnit,
  onAttachmentDrawerOpen,
}: {
  businessUnit: CompanyFinancialResults['businessUnits'][number];
  onAttachmentDrawerOpen: () => void;
}) => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const { toggleEstimateStatus } = useToggleEstimateStatus();
  const documentation = useDocumentationData(businessUnit.financials?.id, onAttachmentDrawerOpen);

  const { editableFinancials, eligible, total } = useMemo(() => {
    const businessUnitFinancials = financials.businessUnits.find((bu) => bu.id === businessUnit.id);

    if (!businessUnitFinancials) {
      return { activities: [], total: undefined, eligible: undefined };
    }
    const activityFinancials = businessUnitFinancials.activities.map((act) => ({
      ...(act.financials as Financials),
      name: act.activity?.name ?? 'NA',
    }));

    const notEligibleFinancials = {
      ...businessUnitFinancials.financials,
      name: 'Taxonomy-non-eligible activities',
    };

    const editableFinancials = [...activityFinancials, notEligibleFinancials];
    const eligible = aggregateFinancials(activityFinancials);
    const total = aggregateFinancials(editableFinancials);

    return { editableFinancials, total, eligible };
  }, [financials, businessUnit]);

  const isEstimate = useMemo(
    () => editableFinancials?.every((financial) => financial.isEstimate) ?? false,
    [editableFinancials]
  );

  return {
    documentation,
    isLocked,
    currency,
    editableFinancials,
    total,
    eligible,
    isEstimate,
    toggleEstimateStatus,
  };
};

export const useGetFinancialsSummaryData = () => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { documentation, attachmentBox } = useDocumentationData(
    financials.notEligibleFinancials?.id,
    onAttachmentDrawerOpen
  );

  const totalFinancials = useMemo(
    () =>
      aggregateFinancials([
        financials.totalNotEligibleFinancials,
        financials.totalEligibleFinancials,
      ]),
    [financials]
  );

  const documentationData = useMemo(
    () => ({
      isAttachmentDrawerOpen,
      onAttachmentDrawerOpen,
      onAttachmentDrawerClose,
      documentation,
      attachmentBox,
    }),
    [
      isAttachmentDrawerOpen,
      onAttachmentDrawerOpen,
      onAttachmentDrawerClose,
      documentation,
      attachmentBox,
    ]
  );

  return {
    notEligibleFinancials: financials.totalNotEligibleFinancials,
    eligibleFinancials: financials.totalEligibleFinancials,
    totalFinancials,
    documentationData,
    currency,
    isLocked,
  };
};

export const useGetCompanyNotEligibleTableData = () => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const { toggleEstimateStatus } = useToggleEstimateStatus();
  const notEligibleFinancials = useMemo(() => financials.notEligibleFinancials, [financials]);

  return {
    financials: notEligibleFinancials,
    currency,
    isLocked,
    toggleEstimateStatus,
  };
};

export const useGetTotalComparisonTableData = () => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const { toggleEstimateStatus } = useToggleEstimateStatus();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { documentation, attachmentBox } = useDocumentationData(
    financials.comparisonTotalFinancials?.id,
    onAttachmentDrawerOpen
  );

  const documentationData = useMemo(
    () => ({
      isAttachmentDrawerOpen,
      onAttachmentDrawerOpen,
      onAttachmentDrawerClose,
      documentation,
      attachmentBox,
    }),
    [
      isAttachmentDrawerOpen,
      onAttachmentDrawerOpen,
      onAttachmentDrawerClose,
      documentation,
      attachmentBox,
    ]
  );

  const totalFinancials = useMemo(
    () =>
      aggregateFinancials([
        financials.totalEligibleFinancials,
        financials.totalNotEligibleFinancials,
      ]),
    [financials]
  );

  return {
    comparisonFinancials: financials.comparisonTotalFinancials,
    totalFinancials,
    isLocked,
    currency,
    documentationData,
    toggleEstimateStatus,
  };
};
