import usePortfolios, {
  useDeletePortfolioCompany,
  useUpdatePortfolioCompanyMarketValue,
  useUploadPortfolioCompaniesFinancials,
} from 'containers/Portfolios/Portfolios.hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { ActionMenu, HelpTooltip, SearchInput, Table } from 'Molecules';
import { Box, HStack, ToastId, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import {
  Button,
  Checkbox,
  EmptyState,
  NumberInput,
  NumberInputProps,
  Tag,
  Tooltip,
  VisibleIf,
} from 'Atoms';
import { AvatarWithName } from 'Organisms';
import { useCurrentCompany, useDebounce, useToast } from 'utils/hooks';
import { PortfolioCompany, ValueOfInvestments, useUserCompaniesQuery } from 'models';
import { CommentIcon, DeleteIcon, EditIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { EligibilityStatus, PortfolioCompanyEditorModal } from './PortfolioCompanyEditor';
import { NudgePortfolioCompanyModal } from 'containers/Portfolios/pieces/NudgePortfolioCompanyModal';
import { ReportPeriods } from './PortfolioView';
import { NotEligibleTag, PortfolioCompanyProgress as TaxonomyProgress } from './PortfolioTaxonomy';
import { useUserData } from '@nhost/react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatQuarterYear } from 'utils/date';

const InvestmentInput = React.memo(
  (
    props: NumberInputProps & {
      reportPeriod: ReportPeriods;
      valueOfInvestments: ValueOfInvestments;
      maxValue: number;
      isAuditor?: boolean;
      isQuarter?: boolean;
    }
  ) => {
    const [localValue, setLocalValue] = useState<number>(props.value ? Number(props.value) : 0);
    const { portfolioId } = useParams();
    const toast = useToast();
    const { t } = useTranslation('portfolio');

    const [prevToastId, setPrevToastId] = useState<ToastId | undefined>(undefined);

    const upsertPortfolioCompaniesFinancials = useUploadPortfolioCompaniesFinancials();

    const updateValueOfInvestments = () => {
      const modifiedValueOfInvestments = {
        ...props.valueOfInvestments,
        [props.reportPeriod]: localValue,
      };
      try {
        upsertPortfolioCompaniesFinancials([
          {
            id: props.id ?? '',
            portfolioId: portfolioId ?? '',
            valueOfInvestments: modifiedValueOfInvestments,
          },
        ]);
        toast({
          text: `${t('portfolio:toasts.success')}`,
        });
      } catch (error) {
        toast({
          text: `${t('portfolio:toasts.fail')}`,
          variant: 'danger',
        });
      }
    };

    const debouncedValue = useDebounce<number>(localValue, 750);

    const onValueChange = (value: number) => {
      if (value > props.maxValue) {
        const id = toast({
          text: `${t('portfolio:investmentValue.notice')}`,
          variant: 'danger',
          duration: 5000,
          prevId: prevToastId,
        });
        setPrevToastId(id);
        value = props.maxValue;
      }

      setLocalValue(value);
    };

    useEffect(() => {
      if (props.value !== localValue) {
        updateValueOfInvestments();
      }
    }, [debouncedValue, props.value]);

    const investmentTooltip = props.isAuditor
      ? ''
      : !props.isQuarter
        ? `${t('portfolio:investmentValue.editTooltipQuarter')}`
        : '';

    return (
      <Tooltip label={investmentTooltip}>
        <NumberInput
          {...props}
          keepWithinRange
          clampValueOnBlur
          value={localValue}
          onChange={onValueChange}
          format={(value) => {
            return value.toLocaleString('en', {
              useGrouping: true,
            });
          }}
        />
      </Tooltip>
    );
  }
);

const MarketValueInput = React.memo(
  (
    props: NumberInputProps & {
      isAuditor?: boolean;
      isYear?: boolean;
      minValue: number;
    }
  ) => {
    const [localValue, setLocalValue] = useState<number>(props.value ? Number(props.value) : 0);
    const toast = useToast();
    const { t } = useTranslation('portfolio');

    const updateMarketValue = useUpdatePortfolioCompanyMarketValue();

    const onUpdate = () => {
      try {
        updateMarketValue(props.id ?? '', localValue);
        toast({
          text: `${t('portfolio:toasts.success')}`,
        });
      } catch (error) {
        toast({
          text: `${t('portfolio:toasts.fail')}`,
          variant: 'danger',
        });
      }
    };

    const debouncedValue = useDebounce<number>(localValue, 750);

    const onValueChange = (value: number) => {
      setLocalValue(value);
    };

    useEffect(() => {
      if (Number(debouncedValue) < Number(props.minValue)) {
        toast({
          text: `${t('portfolio:fields.marketValue.notice')}`,
          variant: 'danger',
          duration: 5000,
        });
        setLocalValue(props.minValue);
        return;
      }
      if (Number(debouncedValue) !== Number(props.value)) {
        onUpdate();
      }
    }, [debouncedValue, props.value]);

    const enterpriseTooltip = props.isAuditor
      ? ''
      : !props.isYear
        ? `${t('portfolio:investmentValue.editTooltipYearly')}`
        : '';

    return (
      <Tooltip label={enterpriseTooltip}>
        <NumberInput
          {...props}
          keepWithinRange
          clampValueOnBlur
          value={localValue}
          onChange={onValueChange}
          format={(value) => {
            return value.toLocaleString('en', {
              useGrouping: true,
            });
          }}
        />
      </Tooltip>
    );
  }
);

const PaiTag = ({ progress }: { progress: number }) => {
  const { t } = useTranslation('portfolio');
  if (progress === 0) {
    return <Tag variant="undefined"> {t('portfolio:progress.notStarted')}</Tag>;
  } else if (progress < 1) {
    return <Tag variant="info"> {t('portfolio:progress.inProgress')}</Tag>;
  } else if (isNaN(progress)) {
    return <Typography>--</Typography>;
  }
  return <Tag variant="success"> {t('portfolio:progress.completed')}</Tag>;
};

export const protfolioCompaniesSorter = (a?: string, b?: string) => {
  return a?.localeCompare(b ?? '') ?? 1;
};

export const PortfolioCompanies = ({
  year,
  reportPeriod,
  paiProgressPerCompany,
  isAuditor = false,
}: {
  year: string;
  reportPeriod: ReportPeriods;
  paiProgressPerCompany: Record<string, number>;
  isAuditor?: boolean;
}) => {
  const { t } = useTranslation('portfolio');
  const { getPortfolio } = usePortfolios();
  const user = useUserData();

  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<PortfolioCompany[]>([]);
  const [currentEdit, setCurrentEdit] = useState<PortfolioCompany | undefined>(undefined);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [showNudger, setShowNudger] = useState<boolean>(false);
  const deletePortfolioCompany = useDeletePortfolioCompany();

  const { company } = useCurrentCompany();

  const { portfolioId } = useParams();
  const portfolio = getPortfolio(portfolioId ?? '', year);

  const portfolioCompanies = useMemo(() => {
    return (
      portfolio?.portfolioCompanies
        ?.filter((pc) => formatQuarterYear(pc.quarter, pc.year) === year)
        .sort((a, b) => protfolioCompaniesSorter(a?.company?.name, b?.company?.name)) ?? []
    );
  }, [portfolio, year]);

  const filteredPortfolioCompanies = useMemo(() => {
    if (!searchValue) return portfolioCompanies;
    return portfolioCompanies?.filter((portfolioCompany) => {
      return portfolioCompany.name?.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [searchValue, portfolioCompanies, portfolio, year]);

  return (
    <VStack spacing="md" width="100%" alignItems="center" paddingTop="16px">
      <VStack spacing="16px" width="100%">
        <HStack justifyContent="space-between" width="100%">
          <SearchInput
            search={searchValue}
            setSearch={setSearchValue}
            placeholder={t('portfolio:investmentValue.filterCompanies')}
            minW="200px"
          />
          <HStack spacing="8px">
            <Button
              variant="ghost"
              leftIcon={<CommentIcon color="inherit" />}
              onClick={() => {
                setSelectedRows(filteredPortfolioCompanies ?? []);
                setShowNudger(true);
              }}
            >
              {t('portfolio:reminderReport')}
            </Button>
          </HStack>
        </HStack>
      </VStack>

      {!filteredPortfolioCompanies.length && searchValue ? (
        <Box w="100%" h="100%">
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            callToAction={{
              text: t('common:search.filter.emptyBtn'),
              variant: 'secondary',
              onClick: () => {
                setSearchValue('');
              },
              leftIcon: <RefreshIcon color="inherit" />,
            }}
            component={true}
          />
        </Box>
      ) : (
        <Table
          columns={[
            {
              header: () => (
                <Checkbox
                  isDisabled={!filteredPortfolioCompanies.length}
                  isChecked={selectedRows.length === filteredPortfolioCompanies?.length}
                  onChange={(e) =>
                    setSelectedRows(
                      e.currentTarget.checked ? (filteredPortfolioCompanies ?? []) : []
                    )
                  }
                />
              ),
              id: 'checkbox',
              cell: ({ row }) => (
                <Checkbox
                  isChecked={
                    !!selectedRows.find((x) => (row.original as PortfolioCompany).id === x.id)
                  }
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setSelectedRows((cur) => [...cur, row.original as PortfolioCompany]);
                    } else
                      setSelectedRows((cur) => [
                        ...cur.filter((item) => item.id !== (row.original as PortfolioCompany).id),
                      ]);
                  }}
                />
              ),
            },
            {
              header: () => <Typography variant="bodyStrong">{t('common:company')}</Typography>,
              id: 'name',
              meta: {
                colSpan: 1,
              },
              cell: ({ row }) => {
                const hasAccessToCompany = userCompaniesData?.data?.companies?.find(
                  (c) => c.company?.id === row.original?.company?.id
                );

                return (
                  <HStack
                    width="100%"
                    onClick={() => hasAccessToCompany && navigate(`/${row.original?.company?.id}`)}
                  >
                    <Tooltip label={hasAccessToCompany ? t('common:goToCompany') : ''}>
                      <Typography variant="bodyStrong">
                        {(row.original as PortfolioCompany).company?.name ??
                          (row.original as PortfolioCompany).estimateCompany?.name ??
                          t('portfolio:fields.noName')}
                      </Typography>
                    </Tooltip>
                  </HStack>
                );
              },
            },
            {
              header: () => (
                <HStack spacing="8px">
                  <VStack spacing="0px" alignItems="start">
                    <Typography variant="bodyStrong">
                      {t('portfolio:investmentValue.value')}
                    </Typography>
                    <Typography variant="micro">{company?.currency}</Typography>
                  </VStack>
                  <HelpTooltip label={t('companiesView.tooltips.valueOfInvestment')} />
                </HStack>
              ),
              accessorKey: 'amount',
              cell: ({ row }) => {
                const valueOfInvestments = row.original.valueOfInvestments;
                const quarterValue: number = valueOfInvestments?.[reportPeriod];
                const yearValue: number =
                  (valueOfInvestments?.q1 +
                    valueOfInvestments?.q2 +
                    valueOfInvestments?.q3 +
                    valueOfInvestments?.q4) /
                  4;
                const id = row.original.id;
                const isQuarter = reportPeriod !== 'year';
                return (
                  <InvestmentInput
                    isDisabled={!isQuarter || isAuditor}
                    width="100%"
                    id={id}
                    reportPeriod={reportPeriod}
                    valueOfInvestments={valueOfInvestments}
                    maxValue={row.original.marketValue}
                    value={isQuarter ? quarterValue : yearValue}
                    isAuditor={isAuditor}
                    isQuarter={isQuarter}
                  />
                );
              },
            },
            {
              header: () => (
                <HStack spacing="9px">
                  <VStack spacing="0px" alignItems="start">
                    <Typography variant="bodyStrong">
                      {t('portfolio:investmentValue.enterprise')}
                    </Typography>
                    <Typography variant="micro">{company?.currency}</Typography>
                  </VStack>
                  {reportPeriod !== ReportPeriods.year ? (
                    <HelpTooltip label={t('companiesView.tooltips.enterpriseValue')} />
                  ) : (
                    <HelpTooltip label={t('companiesView.tooltips.enterpriseValueYear')} />
                  )}
                </HStack>
              ),
              accessorKey: 'market-value',
              cell: ({ row }) => {
                const id = row.original.id;
                const isYear = reportPeriod === 'year';
                const value = row.original.marketValue;
                const valueOfInvestments = row.original.valueOfInvestments;
                //the minMarketValue is the largest valueOfInvestments
                const minMarketValue: number = Math.max(
                  valueOfInvestments?.q1,
                  valueOfInvestments?.q2,
                  valueOfInvestments?.q3,
                  valueOfInvestments?.q4
                );
                return (
                  <MarketValueInput
                    isDisabled={!isYear || isAuditor}
                    id={id}
                    value={value}
                    isAuditor={isAuditor}
                    isYear={isYear}
                    minValue={minMarketValue}
                  />
                );
              },
            },
            {
              header: () => (
                <Typography variant="bodyStrong">
                  {t('portfolio:investmentValue.taxonomy')}
                </Typography>
              ),
              accessorKey: 'eu-taxonomy-status',
              cell: ({ row }) => {
                const isTaxonomyEligible =
                  row?.original?.eligibilityStatus !== EligibilityStatus.notEligible;
                return (
                  <>
                    <VisibleIf condition={!isTaxonomyEligible}>
                      <NotEligibleTag />
                    </VisibleIf>

                    <VisibleIf condition={isTaxonomyEligible}>
                      <TaxonomyProgress
                        progress={
                          row?.original?.progress ?? {
                            financials: 0,
                            screening: 0,
                            isLocked: false,
                          }
                        }
                      />
                    </VisibleIf>
                  </>
                );
              },
            },
            {
              header: () => (
                <Typography variant="bodyStrong">{t('portfolio:investmentValue.pai')}</Typography>
              ),
              accessorKey: 'pai-status',
              cell: ({ row }) => {
                return <PaiTag progress={paiProgressPerCompany[row.original?.company?.id]} />;
              },
            },
            {
              header: () => (
                <Typography variant="bodyStrong">
                  {t('portfolio:investmentValue.contactPerson')}
                </Typography>
              ),
              accessorKey: 'contact-person',
              meta: {
                colSpan: 1,
              },
              cell: ({ row }) => {
                const contactPersonName = row.original.contactPersonName ?? '';
                return (
                  <>
                    {row.original.contactPersonName ? (
                      <AvatarWithName
                        user={{
                          displayName: contactPersonName,
                          avatarUrl: '',
                        }}
                      />
                    ) : (
                      <Typography variant="body">--</Typography>
                    )}
                  </>
                );
              },
            },
            {
              header: '',
              id: 'actions',
              cell: ({ row }) => (
                <ActionMenu
                  actions={[
                    {
                      name: t('common:actions.edit'),
                      onClick: () => setCurrentEdit(row.original as PortfolioCompany),
                      icon: <EditIcon color="inherit" />,
                      isDisabled: isAuditor,
                    },
                    {
                      name: t('common:actions.delete'),
                      onClick: () => deletePortfolioCompany(row.original as PortfolioCompany),
                      icon: <DeleteIcon color="inherit" />,
                      isDisabled: isAuditor,
                    },
                    {
                      name: t('portfolio:reminderReport'),
                      onClick: () => {
                        setSelectedRows([row.original as PortfolioCompany]);
                        setShowNudger(true);
                      },
                      icon: <CommentIcon color="inherit" />,
                    },
                  ]}
                />
              ),
            },
          ]}
          data={filteredPortfolioCompanies}
        />
      )}
      <PortfolioCompanyEditorModal
        portfolioCompany={currentEdit}
        isOpen={isCreating || !!currentEdit}
        onClose={() => {
          setCurrentEdit(undefined);
          setIsCreating(false);
        }}
        currentPortfolioCompanyIds={portfolioCompanies.map((pc) => pc.id)}
        reportYear={year}
      />
      <NudgePortfolioCompanyModal
        portfolioCompanies={selectedRows}
        isOpen={showNudger}
        onClose={() => {
          setShowNudger(false);
          setSelectedRows([]);
        }}
      />
    </VStack>
  );
};
