import { useToast } from '@chakra-ui/react';
import { Modal } from 'Molecules';
import { Suspense, useMemo, useState } from 'react';
import { usePaiBulkActions } from '../BulkUpload.hooks';
import { Button, DateInput } from 'Atoms';
import { Uploader } from 'Features/CSVUploader/Uploader.tsx';

interface PaiBulkUploaderProps {
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: { title?: string; description?: string; button?: string }[];
  onUploadDone?: (object: any[]) => void | Promise<string[]> | boolean;
}

export const PaiBulkUploader = ({ isOpen, onClose }: PaiBulkUploaderProps) => {
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [reportingPeriod, setReportingPeriod] = useState<Date>(new Date());
  const [rejectionMessages, setRejectionMessages] = useState<string[]>([]);
  // const [columnMappings, setColumnMappings] = useState<{ [key: string]: string }>({});
  const [fileName, setFileName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();

  const resetStates = () => {
    setFile(null);
    setIsFileUploaded(false);
    setRejectionMessages([]);
    // setColumnMappings({});
  };

  const { downloadTemplate, uploadTemplate } = usePaiBulkActions();

  const handleDownloadTemplate = () => {
    downloadTemplate(reportingPeriod.getFullYear());
  };

  const handleUploadTemplate = useMemo(
    () => async () => {
      if (!file) return;
      setIsLoading(true);
      const { res: response } = await uploadTemplate(file);
      if (response && response.status === 200) {
        setIsLoading(false);
        onClose();
        toast({
          title: 'Upload successful',
          description: 'Your data has been uploaded successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        setIsLoading(false);
        toast({
          title: 'Upload failed',
          description: 'Your data could not be uploaded. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [file]
  );

  const DEFAULT_DESCRIPTIONS = [
    {
      title: 'Choose reporting year',
      description: 'Select the year where you want to upload data.',
      Component: (
        <DateInput
          width="220px"
          value={reportingPeriod}
          setValue={(date) => date && setReportingPeriod(date)}
          showYearPicker
          dateFormat="yyyy"
          attachToId="pai-bulk-upload-modal"
        />
      ),
    },
    {
      title: 'Download template',
      description: 'Fill in the data for your companies.',
      Component: <Button onClick={handleDownloadTemplate}>Download</Button>,
    },
    {
      title: 'Upload filled in template',
    },
  ];

  const handleTemplateUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
    setFileName(uploadedFile.name);
    setIsFileUploaded(true);
  };

  return (
    <Modal
      size="md"
      title="Upload PAI data"
      isOpen={isOpen}
      onClose={() => {
        resetStates();
        onClose();
      }}
      confirmText="Upload file"
      onConfirm={() => {
        handleUploadTemplate();
      }}
      confirmButtonProps={{ isDisabled: !isFileUploaded, isLoading }}
      contentProps={{ id: 'pai-bulk-upload-modal' }}
    >
      <Suspense>
        <Uploader
          acceptedTypes={['.xlsx']}
          onUpload={handleTemplateUpload}
          isFileUploaded={isFileUploaded}
          setIsFileUploaded={setIsFileUploaded}
          rejectionMessages={rejectionMessages}
          fileName={fileName}
          description={DEFAULT_DESCRIPTIONS}
        />
      </Suspense>
    </Modal>
  );
};
