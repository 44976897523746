import { Thead, Tr, Td, HStack, Box, Table as ChakraTable } from '@chakra-ui/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer.tsx';
import { GetFinancialsDocumentationByIdDocument_ } from 'models/index.ts';
import { StateStatus } from 'Molecules/CardStatus/CardStatus.tsx';
import { HelpTooltip } from 'Molecules/index.ts';
import { InputCard } from 'Molecules/InputCard/InputCard.tsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction.tsx';
import { Typography } from 'Tokens/index.ts';
import { scoreSections } from 'utils/financials.ts';
import { DifferenceRow } from './TableComponents/DifferenceRow.tsx';
import { FinancialTableColumnHeader } from './TableComponents/FinancialColumnHeader.tsx';
import { useGetTotalComparisonTableData } from './FinancialTables.hooks.ts';
import { FinancialInput } from './TableComponents/FinancialInput.tsx';
import { Button, IconButton } from 'Atoms/Buttons/Buttons.tsx';

export const TotalComparisonTable = () => {
  const {
    comparisonFinancials,
    totalFinancials,
    currency,
    documentationData,
    toggleEstimateStatus,
  } = useGetTotalComparisonTableData();
  const { t } = useTranslation(['common', 'financials']);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setIsCollapsed((prevCollapsed) => !prevCollapsed);
  };
  const chevronIcon = isCollapsed ? <ChevronRightIcon /> : <ChevronDownIcon />;

  return (
    <AttachmentDrawer
      isOpen={documentationData.isAttachmentDrawerOpen}
      refetch={[GetFinancialsDocumentationByIdDocument_]}
      onClose={documentationData.onAttachmentDrawerClose}
      attachmentBox={documentationData.attachmentBox}
    >
      <InputCard
        documentation={isCollapsed ? undefined : documentationData.documentation}
        padding={isCollapsed ? '0px ' : undefined}
        header={{
          title: t('financials:inputCards.total.title'),
          subtitle: t('financials:inputCards.total.subtitle'),
          status: comparisonFinancials?.isEstimate ? StateStatus.todo : StateStatus.done,
          onHeaderClick: toggleCollapsed,
          actions: [
            <Button
              key={comparisonFinancials?.id + 'mark-as-done'}
              variant="ghost"
              size="md"
              onClick={(clickEvent) => {
                clickEvent.stopPropagation();
                toggleEstimateStatus(
                  [comparisonFinancials?.id],
                  comparisonFinancials?.isEstimate ?? false
                );
              }}
            >
              {comparisonFinancials?.isEstimate
                ? t('common:actions.done')
                : t('common:actions.edit')}
            </Button>,
            <IconButton
              icon={chevronIcon}
              onClick={(clickEvent) => {
                clickEvent.stopPropagation();
                toggleCollapsed();
              }}
              variant={'ghost'}
              aria-label={''}
            />,
          ],
        }}
        {...{ minWidth: '800px' }}
      >
        {!isCollapsed && (
          <ChakraTable>
            <Thead>
              <FinancialTableColumnHeader title={t('common:activity')} />
              {scoreSections.map((section) => (
                <FinancialTableColumnHeader
                  title={t(
                    `common:financials.${section}${
                      section.includes('adapt') ? t('common:words.short') : ''
                    }`
                  )}
                  helpLabel={t(`common:financials.${section}Tooltip`)}
                  caption={currency}
                />
              ))}
            </Thead>
            <Tr>
              <Td>
                <HStack spacing="10px">
                  <Typography variant="body">{t('financials:financialStatement.title')}</Typography>
                  <HelpTooltip label={t('financials:financialStatement.tooltip')} />
                </HStack>
              </Td>
              {scoreSections.map((section) => (
                <Td>
                  <Box>
                    <FinancialInput section={section} financialsId={comparisonFinancials?.id} />
                  </Box>
                </Td>
              ))}
            </Tr>
            <DifferenceRow
              title={t('financials:differenceRow.title')}
              helpLabel={t('financials:differenceRow.tooltip')}
              totalFinancials={totalFinancials}
              comparisonFinancials={comparisonFinancials}
            />
          </ChakraTable>
        )}
      </InputCard>
    </AttachmentDrawer>
  );
};
