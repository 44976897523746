import { useMemo, useCallback } from 'react';
import { ApolloClient } from '@apollo/client';
import {
  Portfolio,
  PortfoliosDocument_,
  PortfoliosQueryVariables_,
  PortfoliosQuery_,
  usePortfoliosQuery,
  useUpsertPortfolioMutation,
  PortfolioUpsert,
  useUpsertPortfolioCompanyMutation,
  useDeletePortfolioCompanyMutation,
  PortfolioCompany,
  CompaniesFromAllPortfoliosDocument_,
  UserCompaniesDocument_,
  CompanyAssessmentsDocument_,
  SubstantialContributionProportion,
  useUpsertPortfolioCompaniesFinancialsMutation,
  ValueOfInvestments,
  useUpdatePortfolioCompanyMarketValueMutation,
  useUpsertTaxonomyPortfolioMutation,
  useUpsertPaiPortfolioReportMutation,
  useUpsertPortfolioCompaniesReportingPeriodsMutation,
  useUpsertPortfolioCompaniesReportingPeriodsWithPKeyConstraintMutation,
  PaiReportIndicator_Constraint_,
  PaiReportIndicator_Update_Column_,
  PaiReportIndicatorPortfolioCompany_Constraint_,
  GetPaiPortfolioReportDocument_,
  PaiReportRequestsDocument_,
  useGetPaiPortfolioReportQuery,
  useDeletePaiReportIndicatorMutation,
  useDeletePaiReportIndicatorPortfolioCompanyMutation,
  GetTaxonomyPortfolioReportDocument_,
  QuarterEnum_Enum_,
} from 'models';
import { useCreateInitialCompany, useUserSetting } from 'containers/Navigation';
import { useCurrentCompanyId, useCurrentCompany, useToast } from 'utils/hooks';
import { omitMeta } from 'utils';
import { useUserData } from '@nhost/react';
import { nhost } from 'utils/nhost';
import { EligibilityStatus, ReportPeriods } from 'Features/PortfolioView';
import { IndicatorConfiguration } from 'Features/PortfolioPai';
import { difference, omit } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  aggregatePortfolioProgress,
  getAggregatePortfolioScores,
  getAggregatePortfolioSubstantialContribution,
} from 'utils/scores/portfolio';
import { formatQuarterYear, Month } from 'utils/date';

export const portfolioOwnerContext = {
  headers: {
    'x-hasura-role': 'portfolio-owner',
  },
};

export const DEFAULT_PORTFOLIO_DATES = {
  taxonomy: {
    annual: {
      dueDate: { month: Month.Jan, day: 31 },
    },
  },
  pai: {
    q1: {
      sendOutDate: { month: Month.Mar, day: 31 },
      dueDate: {
        month: Month.Apr,
        day: 14,
      },
    },
    q2: {
      sendOutDate: {
        month: Month.Jun,
        day: 30,
      },
      dueDate: { month: Month.Jul, day: 14 },
    },
    q3: {
      sendOutDate: {
        month: Month.Sep,
        day: 30,
      },
      dueDate: { month: Month.Oct, day: 14 },
    },
    q4: {
      sendOutDate: {
        month: Month.Dec,
        day: 31,
      },
      dueDate: { month: Month.Jan, day: 14 },
    },
    annual: {
      dueDate: { month: Month.Jan, day: 31 },
    },
  },
};

const zeroScores: SharedTaxonomyScore = {
  aligned: 0,
  eligible: 0,
  inProgress: 0,
  enabling: 0,
  transitional: 0,
  total: 100,
};

const transformEstimateScoreToNumerics = (scores: {
  [key: string]: { aligned: string; eligible: string; inProgress: string };
}) => {
  return Object.keys(scores).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        aligned: Number(scores[key].aligned),
        eligible: Number(scores[key].eligible),
        inProgress: Number(scores[key].inProgress),
        total: 100,
      },
    };
  }, {});
};

const emptySCProportions: SubstantialContributionProportion = {
  adaptation: { revenue: 0, capex: 0, opex: 0 },
  mitigation: { revenue: 0, capex: 0, opex: 0 },
  biodiversity: { revenue: 0, capex: 0, opex: 0 },
  pollution: { revenue: 0, capex: 0, opex: 0 },
  circular: { revenue: 0, capex: 0, opex: 0 },
  water: { revenue: 0, capex: 0, opex: 0 },
};

const transformPortfolioCompany = (
  portfolioCompany: Portfolio['portfolioCompanies'][0],
  period: string | null
) => {
  if (portfolioCompany.company?.isPortfolioOwner) {
    const portfolios = portfolioCompany.company?.portfolios ?? [];
    const portfoliosInYear = period
      ? portfolios.filter((portfolio) =>
          portfolio.portfolioCompanies.some((c) => formatQuarterYear(c.quarter, c.year) === period)
        )
      : portfolios;
    const portfolioCompanies: PortfolioCompany[] = portfolioCompany.company?.portfolios
      ?.flatMap((p) =>
        p.portfolioCompanies
          .map((pc) => ({
            ...pc,
            id: pc.company?.id ?? 'defaultId',
            company: {
              ...pc.company,
              currency: pc.company?.currency ?? 'NOK',
              name: pc.company?.name ?? 'defaultName',
              id: pc.company?.id ?? 'defaultId',
              isPortfolioOwner: false,
              portfolios: [],
            },
          }))
          .filter((pc) => (period ? formatQuarterYear(pc.quarter, pc.year) === period : true))
      )
      .filter((pc) => !!pc)
      .map((portCo) => transformPortfolioCompany(portCo, period));

    const score =
      portfoliosInYear.length > 0
        ? getAggregatePortfolioScores(portfolioCompanies ?? [], ReportPeriods.year)
        : {
            capex: zeroScores,
            opex: zeroScores,
            revenue: zeroScores,
          };
    const progress =
      portfoliosInYear.length > 0
        ? aggregatePortfolioProgress(portfolioCompanies ?? [], ReportPeriods.year)
        : {
            screening: 0,
            financials: 0,
            isLocked: false,
          };
    const substantialContributionProportion =
      portfoliosInYear.length > 0
        ? getAggregatePortfolioSubstantialContribution(portfolioCompanies ?? [], ReportPeriods.year)
        : emptySCProportions;

    return {
      ...portfolioCompany,
      name: portfolioCompany?.company?.name,
      score,
      scores: score, // Need consistent naming between scores/score, one of them to be deprecated
      progress,
      substantialContributionProportion,
      year: portfolioCompany?.year,
    };
  }
  const isEstimate = !portfolioCompany?.company;
  const eligibilityStatus = portfolioCompany?.eligibilityStatus;
  const progress: PortfolioCompanyScore['progress'] =
    isEstimate || eligibilityStatus === EligibilityStatus.notEligible
      ? { screening: 100, financials: 100, isLocked: false }
      : {
          screening: portfolioCompany.sharedAssessment?.cachedResult?.progress?.screening ?? 0,
          financials: portfolioCompany.sharedAssessment?.cachedResult?.progress?.financials ?? 0,
          isLocked: portfolioCompany.sharedAssessment?.isLocked || false,
        };

  const score: PortfolioCompanyScore['scores'] = isEstimate
    ? (transformEstimateScoreToNumerics(portfolioCompany?.estimateCompany?.scores) ?? {})
    : eligibilityStatus === EligibilityStatus.notEligible
      ? { capex: zeroScores, opex: zeroScores, revenue: zeroScores }
      : (portfolioCompany.sharedAssessment?.cachedResult?.score ?? {});

  const substantialContributionProportion: SubstantialContributionProportion = isEstimate
    ? emptySCProportions
    : (portfolioCompany.sharedAssessment?.cachedResult?.objectivesState
        ?.substantialContributionProportion ?? emptySCProportions);

  return {
    ...portfolioCompany,
    name: portfolioCompany?.company?.name,
    score,
    scores: score, // Need consistent naming between scores/score, one of them to be deprecated
    progress,
    substantialContributionProportion,
    year: portfolioCompany?.year,
  };
};

const transformPortfolios = (portfolios: Portfolio[], year: string | null) => {
  const portfoliosInYear = year
    ? portfolios.filter((portfolio) =>
        portfolio.portfolioCompanies.some((c) => formatQuarterYear(c.quarter, c.year) === year)
      )
    : portfolios;
  return portfoliosInYear.map((portfolio) => ({
    ...portfolio,
    portfolioCompanies: portfolio.portfolioCompanies
      .filter((c) => !!c.company?.id || !!c.estimateCompany?.id)
      .map((portCo) => transformPortfolioCompany(portCo, year)),
  }));
};

export const fetchPortfolios = async (client: ApolloClient<object>, companyId: string) => {
  const { data } = await client.query<PortfoliosQuery_, PortfoliosQueryVariables_>({
    query: PortfoliosDocument_,
    variables: {
      companyId,
    },
    context: portfolioOwnerContext,
  });
  return data?.portfolios ?? [];
};

export type SharedTaxonomyScore = {
  total?: number | null;
  aligned?: number | null;
  inProgress?: number | null;
  eligible?: number | null;
  enabling?: number | null;
  transitional?: number | null;
};

export type PortfolioCompanyScore = {
  scores: {
    capex: SharedTaxonomyScore;
    opex: SharedTaxonomyScore;
    revenue: SharedTaxonomyScore;
  };
  progress: {
    financials: number;
    screening: number;
    isLocked: boolean;
  };
};

export default function usePortfolios() {
  const { companyId } = useCurrentCompanyId();
  const {
    data,
    loading,
    refetch: refetchPortfolios,
  } = usePortfoliosQuery({
    variables: {
      companyId,
    },
    context: portfolioOwnerContext,
    skip: !companyId,
    fetchPolicy: 'network-only',
  });
  const portfolioList = useMemo(() => {
    return transformPortfolios(data?.portfolios ?? [], null);
  }, [data]);

  const getPortfolio = useCallback(
    (id: string, period: string | null) => {
      const correctPortfolio = portfolioList.find((p) => p.id === id);
      return {
        ...correctPortfolio,
        allCompaniesInPortfolio: correctPortfolio?.portfolioCompanies ?? [],
        portfolioCompanies:
          correctPortfolio?.portfolioCompanies.filter(
            (c) => formatQuarterYear(c.quarter, c.year) === period
          ) ?? [],
        allYears: [
          ...new Set(
            correctPortfolio?.portfolioCompanies.map((c) => formatQuarterYear(c.quarter, c.year))
          ),
        ],
      };
    },
    [portfolioList]
  );
  return { portfolios: portfolioList, getPortfolio, loading, refetchPortfolios };
}

export function useUpsertPortfolio() {
  const [upsertPortfolioMutation] = useUpsertPortfolioMutation({
    refetchQueries: [PortfoliosDocument_],
    awaitRefetchQueries: true,
    context: portfolioOwnerContext,
  });
  const { companyId } = useCurrentCompanyId();
  return useCallback(
    (data: PortfolioUpsert) =>
      upsertPortfolioMutation({
        variables: {
          portfolio: {
            ...omitMeta(data),
            id: data.id,
            ownerCompanyId: companyId,
            totalAmount: 0, // Unused. Use sum of portfolioCompany.amount instead for now
          },
        },
      }).then((res) => res.data?.portfolio),
    [upsertPortfolioMutation, companyId]
  );
}

export const useUpsertPortfolioCompany = () => {
  const createInitialCompany = useCreateInitialCompany();

  const [upsertPortfolioCompany] = useUpsertPortfolioCompanyMutation({
    context: portfolioOwnerContext,
    refetchQueries: [
      CompaniesFromAllPortfoliosDocument_,
      UserCompaniesDocument_,
      CompanyAssessmentsDocument_,
      PortfoliosDocument_,
    ],
    awaitRefetchQueries: true,
  });
  const user = useUserData();

  return useCallback(
    ({
      company,
      portfolioId,
      amount,
      contactPersonName,
      contactPersonEmail,
      marketValue,
      eligibilityStatus,
      year,
      quarter,
      createFirstBusinessUnit = true,
    }: {
      company?: {
        id: string | undefined;
        name: string;
        currency: string;
      };
      portfolioId: string;
      amount: number;
      marketValue?: number;
      contactPersonName: string;
      contactPersonEmail: string;
      eligibilityStatus: EligibilityStatus;
      year?: number;
      quarter: QuarterEnum_Enum_;
      createFirstBusinessUnit?: boolean;
    }) => {
      if (!company?.id) {
        return createInitialCompany(
          user?.id ?? '',
          company?.name,
          false,
          false,
          company?.currency ?? 'NOK',
          createFirstBusinessUnit
        ).then((companyId) => {
          return upsertPortfolioCompany({
            variables: {
              portfolioCompany: {
                portfolioId,
                companyId: companyId,
                scores: {},
                contactPersonEmail: contactPersonEmail,
                contactPersonName: contactPersonName,
                marketValue: marketValue,
                eligibilityStatus: eligibilityStatus,
                year,
                quarter: quarter,
                valueOfInvestments: {
                  q1: amount,
                  q2: amount,
                  q3: amount,
                  q4: amount,
                },
              },
            },
          })
            .then(async ({ data }) => {
              await nhost.auth.refreshSession();
              return data;
            })
            .then((data) => data?.portfolioCompany ?? null);
        });
      }
      return upsertPortfolioCompany({
        variables: {
          portfolioCompany: {
            portfolioId,
            companyId: company?.id ?? undefined,
            scores: {},
            contactPersonEmail: contactPersonEmail,
            contactPersonName: contactPersonName,
            marketValue: marketValue,
            eligibilityStatus: eligibilityStatus,
            valueOfInvestments: {
              q1: amount ?? 0,
              q2: amount ?? 0,
              q3: amount ?? 0,
              q4: amount ?? 0,
            },
            year: year,
            quarter: quarter,
          },
        },
      }).then(({ data }) => data?.portfolioCompany ?? null);
    },
    [upsertPortfolioCompany, createInitialCompany, user]
  );
};

export const useDeletePortfolioCompany = () => {
  const [deletePortfolioCompany] = useDeletePortfolioCompanyMutation({
    context: portfolioOwnerContext,
    refetchQueries: [CompaniesFromAllPortfoliosDocument_],
    awaitRefetchQueries: true,
  });
  return useCallback(
    ({ id }: PortfolioCompany) => {
      return deletePortfolioCompany({ variables: { id } }).then(
        ({ data }) => data?.portfolioCompany
      );
    },
    [deletePortfolioCompany]
  );
};

export const useUploadPortfolioCompaniesFinancials = () => {
  const [upsertPortfolioCompaniesFinancials] = useUpsertPortfolioCompaniesFinancialsMutation({
    context: portfolioOwnerContext,
    refetchQueries: [PortfoliosDocument_],
    awaitRefetchQueries: true,
  });
  return useCallback(
    (
      portfolioCompanies: {
        id: string;
        portfolioId: string;
        valueOfInvestments: ValueOfInvestments;
      }[]
    ) => {
      upsertPortfolioCompaniesFinancials({
        variables: {
          portfolioCompanies,
        },
      });
    },
    [upsertPortfolioCompaniesFinancials]
  );
};

export const useUpdatePortfolioCompanyMarketValue = () => {
  const [updatePortfolioCompanyMarketValue] = useUpdatePortfolioCompanyMarketValueMutation({
    context: portfolioOwnerContext,
    refetchQueries: [PortfoliosDocument_],
    awaitRefetchQueries: true,
  });
  return useCallback(
    (portfolioCompanyId: string, newMarketValue: number) => {
      updatePortfolioCompanyMarketValue({
        variables: {
          portfolioCompanyId,
          newMarketValue,
        },
      });
    },
    [updatePortfolioCompanyMarketValue]
  );
};

export const useUpsertPortfolioReport = (reportYearNumber: number) => {
  const [upsertTaxonomyPortfolio] = useUpsertTaxonomyPortfolioMutation();
  const [upsertPaiPortfolioReport] = useUpsertPaiPortfolioReportMutation();
  const [upsertPortfolioCompaniesReportingPeriods] =
    useUpsertPortfolioCompaniesReportingPeriodsMutation();
  const [upsertPortfolioCompaniesReportingPeriodsWithPKey] =
    useUpsertPortfolioCompaniesReportingPeriodsWithPKeyConstraintMutation();
  const [deletePaiReportIndicator] = useDeletePaiReportIndicatorMutation();
  const [deletePaiReportIndicatorPortfolioCompany] =
    useDeletePaiReportIndicatorPortfolioCompanyMutation();
  const { company: currentCompany } = useCurrentCompany();

  const { portfolioId } = useParams();
  const toast = useToast();

  const { data: reportPai, loading } = useGetPaiPortfolioReportQuery({
    variables: {
      portfolioId,
      year: reportYearNumber,
    },
    skip: !portfolioId,
    context: portfolioOwnerContext,
  });
  const [paiReportId, paiReportIndicators] = useMemo(() => {
    const report = reportPai?.report[0];
    return [report?.id, report?.selectedIndicators];
  }, [reportPai, loading]);

  const upsertPortfolioReport = useCallback(
    async (
      portfolioCompanyReportingPeriods: {
        id?: string;
        portfolioId: string;
        companyId: string;
        year: number;
        valueOfInvestments?: ValueOfInvestments;
        marketValue?: number;
        quarter?: QuarterEnum_Enum_;
      }[],
      taxonomyData: {
        portfolioId: string;
        contactPersonId: string;
        sendOutDate: Date | string;
        dueDate: Date | string;
        year: number;
        quarter?: QuarterEnum_Enum_;
      },
      paiData: {
        portfolioId: string;
        contactPersonId: string;
        reportingPeriod: Date;
        sendOutDates: object;
        dueDates: object;
        year: number;
        indicators: IndicatorConfiguration[];
      }
    ) => {
      try {
        let res;
        const portfolioCompaniesContainIds = portfolioCompanyReportingPeriods?.every(
          (pc) => !!pc.id
        );
        if (portfolioCompaniesContainIds) {
          res = await upsertPortfolioCompaniesReportingPeriodsWithPKey({
            variables: {
              objects: portfolioCompanyReportingPeriods,
            },
            context: portfolioOwnerContext,
            refetchQueries: [PortfoliosDocument_, PaiReportRequestsDocument_],
          });
        } else {
          res = await upsertPortfolioCompaniesReportingPeriods({
            variables: {
              objects: portfolioCompanyReportingPeriods,
            },
            context: portfolioOwnerContext,
            refetchQueries: [PortfoliosDocument_, PaiReportRequestsDocument_],
          });
        }

        if (res) {
          if (currentCompany?.hasTaxonomyAccess)
            await upsertTaxonomyPortfolio({
              variables: {
                object: {
                  ...taxonomyData,
                },
              },
              context: portfolioOwnerContext,
              refetchQueries: [PortfoliosDocument_, GetTaxonomyPortfolioReportDocument_],
            });

          const newIndicators = {
            data: paiData?.indicators?.map((indicator) => ({
              ...omit(indicator, ['selectedPortfolioCompanyIds', '__typename', 'indicator']),
              paiReportIndicatorPortfolioCompanies: {
                data: indicator?.selectedPortfolioCompanyIds?.length
                  ? indicator.selectedPortfolioCompanyIds.map((companyId) => ({
                      portfolioCompanyId: companyId,
                    }))
                  : [],
                on_conflict: {
                  constraint:
                    PaiReportIndicatorPortfolioCompany_Constraint_.PaiReportIndicatorPortfolioCompanyPortfolioCompanyIdPaiReport_,
                  update_columns: [],
                },
              },
            })),
            on_conflict: {
              constraint:
                PaiReportIndicator_Constraint_.PaiReportIndicatorPaiPortfolioReportIdPaiTableIndicatorRefere_,
              update_columns: [
                PaiReportIndicator_Update_Column_.IsForAllCompanies_,
                PaiReportIndicator_Update_Column_.IsNew_,
                PaiReportIndicator_Update_Column_.Periods_,
              ],
            },
          };

          if (!!paiData?.indicators?.length) {
            await upsertPaiPortfolioReport({
              variables: {
                object: {
                  ...omit(paiData, 'indicators'),
                  selectedIndicators: newIndicators,
                },
              },
              context: portfolioOwnerContext,
              refetchQueries: [
                PortfoliosDocument_,
                GetPaiPortfolioReportDocument_,
                PaiReportRequestsDocument_,
              ],
            });

            const indicatorsToDelete = difference(
              paiReportIndicators?.map((i) => i.indicator.reference),
              newIndicators.data.map((i) => i.paiTableIndicatorReference ?? '')
            );

            if (indicatorsToDelete.length) {
              await deletePaiReportIndicator({
                variables: {
                  paiPortfolioReportId: reportPai ? [paiReportId] : [],
                  paiTableIndicatorReference: indicatorsToDelete,
                },
                context: portfolioOwnerContext,
                refetchQueries: [PortfoliosDocument_, GetPaiPortfolioReportDocument_],
              });
            }

            // All selected indicator portfolio companies from the form
            const indicatorCompaniesToAdd = paiData.indicators.flatMap(
              (indicator) =>
                indicator.selectedPortfolioCompanyIds?.flatMap((companyId) => ({
                  indicatorRef: indicator.paiTableIndicatorReference ?? '',
                  portfolioCompanyId: companyId ?? '',
                })) ?? []
            );
            // Existing indicator portfolio companies from the db
            const existingIndicatorCompanies = paiReportIndicators?.flatMap((indicator) =>
              indicator.paiReportIndicatorPortfolioCompanies.flatMap((company) => ({
                indicatorRef: indicator.paiTableIndicatorReference ?? '',
                portfolioCompanyId: company.portfolioCompanyId ?? '',
              }))
            );

            // Find the difference (entries to delete)
            const indicatorCompaniesToDelete = existingIndicatorCompanies?.filter(
              (existingIndicatorCompany) => {
                return !indicatorCompaniesToAdd.some(
                  (addedIndicatorCompany) =>
                    addedIndicatorCompany.indicatorRef === existingIndicatorCompany.indicatorRef &&
                    addedIndicatorCompany.portfolioCompanyId ===
                      existingIndicatorCompany.portfolioCompanyId
                );
              }
            );

            indicatorCompaniesToDelete?.forEach((indicatorCompany) =>
              deletePaiReportIndicatorPortfolioCompany({
                variables: {
                  indicatorRef: indicatorCompany.indicatorRef,
                  portfolioCompanyId: indicatorCompany.portfolioCompanyId,
                },
                context: portfolioOwnerContext,
                refetchQueries: [PortfoliosDocument_, GetPaiPortfolioReportDocument_],
              })
            );
          }
        }
      } catch (error) {
        console.error(error);
        toast({
          text: 'Something went wrong',
          variant: 'danger',
        });
      }
    },
    [
      upsertTaxonomyPortfolio,
      upsertPaiPortfolioReport,
      upsertPortfolioCompaniesReportingPeriods,
      deletePaiReportIndicator,
      deletePaiReportIndicatorPortfolioCompany,
      reportPai,
      loading,
    ]
  );

  return upsertPortfolioReport;
};

export const useUpdateDefaultReportingPeriod = (companyId: string) => {
  const [defaultReportingPeriodInvestorView, setDefaultReportingPeriodInvestorView] =
    useUserSetting<{ [key: string]: string }>('default-reporting-period-investor-view', {});

  const defaultReportingPeriod = useMemo(
    () =>
      typeof defaultReportingPeriodInvestorView[companyId] === 'string'
        ? defaultReportingPeriodInvestorView[companyId]
        : `Q1-${defaultReportingPeriodInvestorView[companyId] ?? new Date().getFullYear()}`,
    [defaultReportingPeriodInvestorView, companyId]
  );

  const updateDefaultReportingPeriod = useCallback(
    (year: string) => {
      if (defaultReportingPeriod !== year)
        setDefaultReportingPeriodInvestorView({
          ...defaultReportingPeriodInvestorView,
          [companyId]: year,
        });
    },
    [companyId]
  );

  return {
    defaultReportingPeriod,
    updateDefaultReportingPeriod,
  };
};

export const getDefaultDeadline = (reportingYear: number, solutionType: 'taxonomy' | 'pai') => {
  const currentDate = new Date();
  const nextYear = reportingYear + 1;

  const defaultDueDate = new Date(
    nextYear,
    DEFAULT_PORTFOLIO_DATES[solutionType].annual.dueDate.month,
    DEFAULT_PORTFOLIO_DATES[solutionType].annual.dueDate.day
  );

  if (currentDate < defaultDueDate) {
    return defaultDueDate;
  } else {
    const twoWeeksLater = new Date(currentDate);
    twoWeeksLater.setDate(currentDate.getDate() + 14);
    return twoWeeksLater;
  }
};
