import { Box, VStack } from '@chakra-ui/react';
import { GroupFinancials } from 'Features/Financials/FinancialsTables/Tables/GroupFinancials.tsx';
import { FinancialFilter } from '../Header/FinancialsHeader.hooks.ts';
import { BusinessUnitFinancialTable } from './BusinessUnitFinancialTable.tsx';
import { useGetFilteredFinancialsData } from './FinancialTables.hooks.ts';
import { CompanyNotEligibleTable } from './CompanyNotEligibleTable.tsx';
import { FinancialSummary } from './SummaryTable.tsx';
import { TotalComparisonTable } from './TotalComparisonTable.tsx';

export const FilteredFinancials = () => {
  const { filter, reportingGroups, filteredFinancials, financials } =
    useGetFilteredFinancialsData();

  if (filter === FinancialFilter.byId && filteredFinancials) {
    return (
      <BusinessUnitFinancialTable key={filteredFinancials?.id} businessUnit={filteredFinancials} />
    );
  }

  return (
    <VStack spacing="16px" paddingBottom="16px" width="100%" alignItems="flex-start">
      {reportingGroups
        .filter((item) => item.parentId === null)
        .map((rg) => (
          <GroupFinancials
            key={rg.id}
            reportingGroup={rg}
            children={reportingGroups.filter((child) => child.parentId === rg.id)}
            businessUnits={financials.businessUnits.filter((bu) =>
              rg.businessUnits.find((rgBu) => rgBu.businessUnit?.id === bu.businessUnit?.id)
            )}
            allReportingGroups={reportingGroups}
            allBusinessUnits={financials.businessUnits}
            bg="solid"
          />
        ))}
      {financials.businessUnits
        .filter(
          (bu) =>
            !reportingGroups.find((grp) =>
              grp.businessUnits.find((grpBu) => grpBu.businessUnit?.id === bu.businessUnit?.id)
            )
        )
        .map((bu) => (
          <BusinessUnitFinancialTable key={bu?.id} businessUnit={bu} />
        ))}
      <Box width="100%">
        <CompanyNotEligibleTable />
      </Box>
      <Box width="100%">
        <FinancialSummary />
      </Box>
      <Box width="100%">
        <TotalComparisonTable />
      </Box>
    </VStack>
  );
};
