import { Td, HStack, Tr } from '@chakra-ui/react';
import { Financials, ScoreSectionsEnum, AdaptationSectionsEnum } from 'models/types.ts';
import { HelpTooltip } from 'Molecules/index.ts';
import { useCallback } from 'react';
import { CalculatorIcon } from 'Tokens/Icons/Data.tsx';
import { Typography } from 'Tokens/index.ts';
import { formatNumber } from 'utils/numbers.ts';
import { allFinancialSectionKeys } from '../FinancialTables.hooks.ts';

export const FinancialTableFooter = ({
  isTotal = false,
  title,
  financials,
  helpLabel,
  filteredSections = allFinancialSectionKeys,
}: {
  isTotal?: boolean;
  title: string;
  financials?: Partial<Financials>;
  helpLabel?: string;
  filteredSections?: (ScoreSectionsEnum | AdaptationSectionsEnum)[];
}) => {
  if (isTotal) {
    console.log('total footer render');
  }
  const renderTableCells = useCallback(
    (sections: string[]) => {
      if (isTotal) {
        console.log('total footer renderTableCells', financials);
      }
      return sections.map((section) => (
        <Td borderBottom="0px" key={`${financials?.id}-${section}`}>
          <HStack width="100%" justifyContent="flex-end">
            <Typography variant={isTotal ? 'h4' : 'body'} maxWidth="200px" padding="0px">
              {formatNumber(financials?.[section as keyof Financials] ?? 0)}
            </Typography>
          </HStack>
        </Td>
      ));
    },
    [financials, isTotal]
  );

  return (
    <Tr>
      <Td borderBottom="0px">
        <HStack spacing="10px">
          <CalculatorIcon color="text.hint" />
          <Typography variant={isTotal ? 'h4' : 'body'}>{title}</Typography>
          {helpLabel && <HelpTooltip label={helpLabel} />}
        </HStack>
      </Td>
      {renderTableCells(filteredSections)}
    </Tr>
  );
};
