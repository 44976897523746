import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { Button, ButtonWithMenu, EmptyState, IconButton } from 'Atoms';
import { COMPANY_LEVEL } from 'containers/Esrs/EsrsAssessment.hooks';
import { useEsrsAssessmentQuery, useGetFlaggedTargetsQuery } from 'models';
import { ContentHeader, ContentLayout } from 'Molecules';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ActivitiesIllustration, CompanyIllustration, Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { EditIcon, SettingsIcon } from 'Tokens/Icons/Function';
import { useCurrentCompany } from 'utils/hooks';
import { GroupAssessmentOverview } from '../GroupAssessment/GroupAssessmentOverview';
import { Structure } from './Structure';
import { Walktour } from 'walktour';
import { WalktourComponent } from 'Atoms/WalktourTooltip/WalktourTooltip';
import { GlobalContext, useFeatureFlags, useUserSetting } from 'containers/Navigation';
import { ESRS_ASSESSMENT_WALKTOUR } from 'utils/Walktours';
import { AccessTag } from './EsrsGroupAssessmentTable';
import { BusinessUnitStandardsOverview, EsrsAssessmentOverview } from './EsrsOverview';
import { FinancesIcon } from 'Tokens/Icons/Data';
import { MenuSection } from 'Molecules/Menu';
import { useTranslation } from 'react-i18next';
import { EsrsReportDocsGenerator } from '../Report/DocGenerator';
import { DMAExcelDownloader } from './DMA/DMAExcel';

const SELECTED_REPORTING_LEVEL = 'selectedReportingLevel';

export const EsrsAssessment = () => {
  const { esrsAssessmentId = '' } = useParams();
  const { company, loading: companyLoading } = useCurrentCompany();
  const navigate = useNavigate();
  const { t } = useTranslation('esrs');
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>(esrsAssessmentId);
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [walktourStatus, setWalktourStatus] = useUserSetting(
    'esrs-assessments-walktour-status',
    ''
  );
  const { canDownloadEsrsReport } = useFeatureFlags({ company });
  const {
    isOpen: isReportMenuOpen,
    onOpen: onReportMenuOpen,
    onClose: onReportMenuClose,
  } = useDisclosure();

  const { data, loading } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: targets, loading: targetsLoading } = useGetFlaggedTargetsQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { walktourActive, setWalktourActive, setShowWalktourTooltip } = useContext(GlobalContext);

  useEffect(() => {
    setIsTourOpen(walktourActive);
    if (walktourActive) {
      setWalktourStatus('');
    }
  }, [walktourActive]);

  const esrsAssessment = useMemo(() => data?.esrsAssessment, [data]);
  const isGroupCompany = useMemo(() => company?.isGroupOwner ?? false, [company]);
  const subsidiaries = useMemo(() => esrsAssessment?.subsidiaryAssessments ?? [], [esrsAssessment]);
  const reportingUnits = useMemo(() => esrsAssessment?.reportingUnits ?? [], [esrsAssessment]);

  const reportingUnitIds = useMemo(() => reportingUnits.map((ru) => ru.id), [reportingUnits]);

  useEffect(() => {
    if (reportingUnitIds.includes(localStorage.getItem(SELECTED_REPORTING_LEVEL))) {
      setSelectedNodeKey(localStorage.getItem(SELECTED_REPORTING_LEVEL) ?? esrsAssessmentId);
    } else setSelectedNodeKey(esrsAssessmentId);
  }, []);

  const [subsidiaryId, subsidiaryName, isSubsidiary] = useMemo(() => {
    const subsidiary = subsidiaries?.find((s) => s.id === selectedNodeKey);
    return [subsidiary?.company?.id, subsidiary?.company?.name, subsidiary !== undefined];
  }, [subsidiaries, selectedNodeKey]);

  const [
    reportingUnitAssessmentId,
    reportingUnitName,
    isReportingUnitLevel,
    isReportingUnit,
    reportingUnitParentAssessmentId,
  ] = useMemo(() => {
    const reportingUnit = reportingUnits?.find((ru) => ru.id === selectedNodeKey);
    return [
      reportingUnit?.id,
      reportingUnit?.name,
      !reportingUnit?.isCompanyLevel,
      reportingUnit !== undefined,
      reportingUnit?.assessmentId,
    ];
  }, [reportingUnits, selectedNodeKey]);

  useEffect(() => {
    if (!walktourStatus || (!!walktourStatus && walktourStatus) !== 'finished') {
      return setIsTourOpen(true);
    }
    setIsTourOpen(false);
    if (walktourStatus === 'finished' && walktourActive) {
      setWalktourActive(false);
      setShowWalktourTooltip(true);
    }
  }, [walktourStatus]);

  const reportMenuSections: MenuSection[] = [
    {
      actions: [
        {
          id: 'esrs-report-word-doc',
          render: () => (isReportMenuOpen ? <EsrsReportDocsGenerator /> : <></>),
          isDisabled: !canDownloadEsrsReport,
          tooltipText: canDownloadEsrsReport ? '' : 'Contact us to enable this feature',
        },
        {
          id: 'dma-excel',
          render: () => (isReportMenuOpen ? <DMAExcelDownloader /> : <></>),
          isDisabled: !canDownloadEsrsReport,
          tooltipText: canDownloadEsrsReport ? '' : 'Contact us to enable this feature',
        },
      ],
    },
  ];

  return (
    <ContentLayout
      isLoading={loading || targetsLoading || companyLoading}
      backButton={true}
      paddingBottom="0px !important"
      onBackButtonClick={() => {
        localStorage.removeItem(SELECTED_REPORTING_LEVEL);
      }}
      header={
        <ContentHeader
          title={`${data?.esrsAssessment?.reportingYear}`}
          actions={
            <HStack>
              {!isGroupCompany && data?.esrsAssessment?.parentAssessment?.company && (
                <AccessTag
                  logo={data?.esrsAssessment?.parentAssessment?.company.logoUrl ?? ''}
                  name={data?.esrsAssessment?.parentAssessment?.company.name ?? ''}
                  p="0 12px"
                  h="36px"
                  borderRadius="8px"
                />
              )}
              <Button
                className="walktour-materiality-button"
                leftIcon={<SettingsIcon color="inherit" />}
                onClick={() => navigate('settings')}
              >
                Configure
              </Button>

              <ButtonWithMenu
                className="walktour-download-report-button"
                variant="primary"
                menuProps={{
                  isOpen: isReportMenuOpen,
                  onOpen: onReportMenuOpen,
                  onClose: onReportMenuClose,
                  sections: reportMenuSections,
                  size: 'lg',
                  description: t('assessment.report.download'),
                }}
                leftIcon={<FinancesIcon color="text.onAccent" />}
                onClick={() => navigate('report')}
              >
                <Typography variant="bodyStrong" color="text.onAccent">
                  Create report
                </Typography>
              </ButtonWithMenu>
            </HStack>
          }
        />
      }
    >
      <Walktour
        rootSelector="#root"
        isOpen={isTourOpen}
        disableCloseOnClick
        tooltipSeparation={-10}
        disableMaskInteraction
        customCloseFunc={(logic) => {
          logic.close(true);
        }}
        customTooltipRenderer={(props) => WalktourComponent(props, setWalktourStatus)}
        initialStepIndex={Number(walktourStatus)}
        steps={ESRS_ASSESSMENT_WALKTOUR}
      />
      <HStack
        borderTop="1px solid"
        borderTopColor="border.decorative"
        alignItems="stretch"
        flexGrow="1"
        gap="0px"
      >
        <VStack borderRight="1px solid" borderRightColor="border.decorative" flexGrow="1">
          <Structure
            minWidth="200px"
            maxWidth="288px"
            flexGrow="1"
            header={
              <HStack p="12px 16px 16px">
                <Typography variant="bodyStrong">{'Company structure'}</Typography>
                <IconButton
                  variant="ghost"
                  size="sm"
                  aria-label="Edit company structure"
                  icon={<EditIcon />}
                  onClick={() => navigate('config')}
                />
              </HStack>
            }
            topNode={{
              key: esrsAssessmentId,
              title: `${esrsAssessment?.company?.name ?? 'N/A'} overview`,
            }}
            nodes={
              isGroupCompany
                ? subsidiaries.map((s) => ({
                    title: s?.company?.name ?? 'N/A',
                    key: s.id,
                    projectLeader: s?.projectLeader ?? undefined,
                  }))
                : reportingUnits
                    .filter((ru) => !ru.isCompanyLevel)
                    .map((ru) => ({
                      title: ru?.name ?? '',
                      key: ru.id,
                      projectLeader: ru?.projectLeader ?? undefined,
                    }))
            }
            selectedNodeKey={selectedNodeKey}
            onSelectNode={(node) => {
              setSelectedNodeKey(node);
              localStorage.setItem(SELECTED_REPORTING_LEVEL, node);
            }}
            isGroup={isGroupCompany}
          />
        </VStack>
        <VStack height="100%" width="100%">
          {isSubsidiary ? (
            <Box w="100%" h="100%" p="16px">
              <EmptyState
                title={subsidiaryName ?? ''}
                description="See the data from this subsidiary company in a new tab"
                callToAction={{
                  text: 'Open',
                  variant: 'primary',
                  buttonProps: {
                    rightIcon: <ArrowUpRightIcon color="inherit" />,
                  },
                  onClick: () => {
                    if (subsidiaries.find((s) => s.id === selectedNodeKey)?.onboardingStep !== 0)
                      window.open(`/${subsidiaryId}/esrs/${selectedNodeKey}/onboarding`);
                    else window.open(`/${subsidiaryId}/esrs/${selectedNodeKey}`);
                  },
                }}
                icon={<CompanyIllustration boxSize="120px" />}
              />
            </Box>
          ) : esrsAssessment?.materialStandards.length || !!esrsAssessment?.parentAssessment ? (
            isGroupCompany ? (
              <GroupAssessmentOverview
                esrsAssessmentId={selectedNodeKey}
                companyName={esrsAssessment?.company?.name ?? 'N/A'}
              />
            ) : isReportingUnit ? (
              <BusinessUnitStandardsOverview
                businessUnitAssessmentId={reportingUnitAssessmentId}
                companyAssessmentId={reportingUnitParentAssessmentId}
                businessUnitName={reportingUnitName ?? COMPANY_LEVEL}
                isBusinessUnitLevel={isReportingUnitLevel}
              />
            ) : (
              <EsrsAssessmentOverview
                esrsAssessmentId={selectedNodeKey}
                companyName={esrsAssessment?.company?.name ?? 'N/A'}
                targets={targets?.targets}
              />
            )
          ) : (
            <Box w="100%" h="100%" p="16px">
              <EmptyState
                title="Start with materiality assessment"
                description="Add material standards, disclosure requirements and metrics to get started"
                icon={<ActivitiesIllustration boxSize="120px" />}
                callToAction={{
                  text: 'Begin assessment',
                  variant: 'primary',
                  onClick: () => navigate('settings'),
                }}
              />
            </Box>
          )}
        </VStack>
      </HStack>
    </ContentLayout>
  );
};
