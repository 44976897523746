import {
  VStack,
  Box,
  HStack,
  CircularProgress,
  CircularProgressLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Button, EmptyState, Tag } from 'Atoms';
import { isMetricStateMaterial } from 'containers/Esrs/utils';
import { ContentHeader, ContentLayout, Table } from 'Molecules';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { CompanyBothIcon, CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { useCompanyType } from 'utils/hooks';
import { DataCollectionLevel } from '.';
import { MaterialMap, MaterialStandard } from '../MaterialityAssessment/MaterialityAssessment.d';
import { useGetMaterialityAssessment } from '../MaterialityAssessment/MaterialityAssessment.hooks';
import { MaterialityAssessmentHeader } from '../MaterialityAssessment/MaterialitySettingsHeader';
import { SettingsIcon } from 'Tokens/Icons/Function';
import { TagBulkEditingModal } from './TagBulkEditingModal';
import {
  useGetParentEsrsAssessmentIdQuery,
  useGetRequiredTagsMaterialMetricsQuery,
  useGetSubsidiaryRequiredTagsMaterialMetricsQuery,
} from 'models';

export const getMaterialStandards = (materialityAssessments: MaterialMap[]): MaterialStandard[] => {
  return materialityAssessments.flatMap((mAssessment) =>
    mAssessment.materialStandards.filter((materialStandard) => {
      const hasMaterialMetrics = materialStandard.metrics.some((materialMetric) =>
        isMetricStateMaterial(materialMetric.isMaterial)
      );
      const hasParentMaterialMetrics = materialStandard.parentCompanyMetrics.some(
        (materialMetric) =>
          materialMetric.isMaterial &&
          materialMetric?.dataCollection === DataCollectionLevel.subsidiaries
      );

      const isStandardMaterial = materialStandard.isMaterial && hasMaterialMetrics;
      const isParentStandardMaterial =
        materialStandard.isParentMaterial && hasParentMaterialMetrics;

      return !!materialStandard.id && (isStandardMaterial || isParentStandardMaterial);
    })
  );
};

const checkMetricsLevel = (
  level: DataCollectionLevel,
  metrics: MaterialStandard['metrics']
): boolean => {
  return metrics
    .filter((m) => m.metric.childMetricsCount.aggregate?.count === 0)
    .every((metric) => metric?.dataCollection === level);
};

const getRelevantMaterialMetrics = (row: MaterialStandard) => {
  const parentMetrics = row.isParentMaterial ? row.parentCompanyMetrics : [];

  const metrics = row.metrics.filter((m) => {
    const parentMetric = parentMetrics.find((pm) => pm.metricRef === m.metricRef);
    const isMaterialForParent =
      parentMetric?.isMaterial && parentMetric?.dataCollection === DataCollectionLevel.subsidiaries;
    return (
      (isMaterialForParent || m.isMaterial) && m.metric.childMetricsCount.aggregate?.count === 0
    );
  });

  return metrics;
};

export const IconWithText = ({ icon, text }: { icon: React.ReactNode; text: string }) => (
  <HStack>
    {icon}
    <Typography variant="body">{text}</Typography>
  </HStack>
);

export const DataGatheringLevelStatus = ({
  isGroup,
  metrics,
}: {
  isGroup: boolean;
  metrics: MaterialStandard['metrics'];
}) => {
  if (isGroup) {
    console.log(
      'group ',
      metrics,
      metrics.filter((m) => m.metric.childMetricsCount.aggregate?.count === 0),
      checkMetricsLevel(DataCollectionLevel.group, metrics)
    );
    if (checkMetricsLevel(DataCollectionLevel.group, metrics))
      return (
        <IconWithText icon={<CompanyTopDownIcon color="text.default" />} text="Parent company" />
      );
    else if (checkMetricsLevel(DataCollectionLevel.subsidiaries, metrics))
      return (
        <IconWithText icon={<CompanyBottomUpIcon color="text.default" />} text="Subsidiaries" />
      );
    return <IconWithText icon={<CompanyBothIcon color="text.default" />} text="Mixed" />;
  } else {
    if (checkMetricsLevel(DataCollectionLevel.company, metrics))
      return (
        <IconWithText icon={<CompanyTopDownIcon color="text.default" />} text="Company level" />
      );
    else if (checkMetricsLevel(DataCollectionLevel.reportingUnits, metrics))
      return (
        <IconWithText icon={<CompanyBottomUpIcon color="text.default" />} text="Business units" />
      );
    return <IconWithText icon={<CompanyBothIcon color="text.default" />} text="Mixed" />;
  }
};

export const DataCollectionSetupStatusTag = ({
  isDataCollected,
  helpLabel,
}: {
  isDataCollected: boolean;
  helpLabel?: string;
}) => {
  return (
    <Tag
      size="sm"
      title={isDataCollected ? 'Done' : 'To setup'}
      helpLabel={helpLabel}
      helpTooltipPlacement="bottom-end"
      variant={isDataCollected ? 'success' : 'warning'}
      borderRadius="6px"
    />
  );
};

const DataCollectionStandardHeader = ({ name, reference }: { name: string; reference: string }) => {
  return (
    <VStack spacing={0} alignItems="start">
      <Typography variant="bodyStrong">{name}</Typography>
      <Typography variant="micro">{reference}</Typography>
    </VStack>
  );
};

export const ConfigurationBanner = ({
  type,
  ratio,
  ratioString,
}: {
  type: 'standard' | 'disclosureRequirement';
  ratio: number;
  ratioString: string;
}) => {
  return (
    <HStack
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      spacing="16px"
      p="16px"
      border="1px solid"
      borderRadius="8px"
      borderColor={'border.unknown'}
      bg="bg.unknown.muted"
    >
      <VStack alignItems="start" spacing="0px">
        <Typography variant="h3">
          Configure data collection for all{' '}
          {type === 'standard' ? 'standards' : 'disclosure requirements'}
        </Typography>
        <Typography variant="body">
          Make sure to check and configure all{' '}
          {type === 'standard' ? 'standards' : 'disclosure requirements'} below. Once everything is
          configured, the status will automatically change from “To set up” to “Done”.
        </Typography>
      </VStack>

      <CircularProgress size="61px" thickness="3px" color="text.muted" value={ratio * 100}>
        <CircularProgressLabel as={Typography} variant="h1">
          {ratioString}
        </CircularProgressLabel>
      </CircularProgress>
    </HStack>
  );
};

export const DataCollectionSetup = ({
  isOnboarding,
  onChangeTab,
  setStep,
}: {
  isOnboarding: boolean;
  onChangeTab?: ((tabId: string) => void) | null;
  setStep?: (step: number) => void;
}) => {
  const { esrsAssessmentId } = useParams();
  const { materialityAssessments, loading } = useGetMaterialityAssessment(esrsAssessmentId);
  const { isGroup } = useCompanyType();
  const { data } = useGetParentEsrsAssessmentIdQuery({ variables: { esrsAssessmentId } });

  const parentEsrsAssessmentId = useMemo(() => data?.esrsAssessment?.parentAssessmentId, [data]);

  const { data: groupRequiredTags, loading: groupRequiredTagsLoading } =
    useGetRequiredTagsMaterialMetricsQuery({
      variables: { esrsAssessmentId },
      skip: !esrsAssessmentId || !isGroup,
    });

  const { data: subsidiaryRequiredTags, loading: subsidiaryRequiredTagsLoading } =
    useGetSubsidiaryRequiredTagsMaterialMetricsQuery({
      variables: {
        esrsAssessmentId,
        parentEsrsAssessmentId: parentEsrsAssessmentId || esrsAssessmentId,
      },
      skip: !esrsAssessmentId || isGroup,
    });

  const materialMetrics = useMemo(() => {
    const materialMetricsWithTagsData = isGroup ? groupRequiredTags : subsidiaryRequiredTags;
    return materialMetricsWithTagsData?.esrs_MaterialMetric ?? [];
  }, [groupRequiredTags, subsidiaryRequiredTags, isGroup]);

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const navigate = useNavigate();

  const materialStandards = useMemo(() => {
    return getMaterialStandards(materialityAssessments);
  }, [materialityAssessments]);

  const materialStandardsIds = useMemo(
    () => materialStandards.map((m) => m.id),
    [materialStandards]
  );

  const configuredMaterialStandards = useMemo(() => {
    return materialStandards.filter((standard) => standard.isDataCollected);
  }, [materialStandards]);

  const [configurationRatio, configurationRatioString] = useMemo(() => {
    return [
      configuredMaterialStandards.length / materialStandards.length,
      `${configuredMaterialStandards.length}/${materialStandards.length}`,
    ];
  }, [materialStandards]);

  const columns: ColumnDef<MaterialStandard>[] = useMemo(() => {
    return [
      {
        header: 'Standard',
        accessorKey: 'standardName',
        enableSorting: false,
        cell: ({ row }) => {
          const { standardName, standardRef } = row.original;
          return <DataCollectionStandardHeader name={standardName} reference={standardRef} />;
        },
        meta: {
          width: '60%',
        },
      },
      {
        header: 'Data gathering level',
        accessorKey: 'dataGathering',
        enableSorting: false,
        cell: ({ row }) => {
          const metrics = getRelevantMaterialMetrics(row.original);
          return <DataGatheringLevelStatus isGroup={isGroup} metrics={metrics} />;
        },
        meta: {
          width: '20%',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        cell: ({ row }) => {
          const isDataCollected = row.original.isDataCollected;
          return <DataCollectionSetupStatusTag isDataCollected={isDataCollected} />;
        },
        meta: {
          width: '20%',
        },
      },
    ];
  }, [materialStandards, isGroup]);

  return (
    <ContentLayout
      variant="inline.nopad"
      header={isOnboarding && <ContentHeader title="Data collection setup" />}
      height={isOnboarding ? 'fit-content' : undefined}
      isLoading={loading}
    >
      {!!materialStandards.length ? (
        <VStack mt={isOnboarding ? '32px' : '28px'} w="100%" spacing="36px">
          {!isOnboarding && <MaterialityAssessmentHeader title="Manage data collection setup" />}

          <VStack w="100%" spacing="8px" alignItems="start">
            <Typography variant="h2">Required and voluntary configurations</Typography>
            <Typography variant="body">
              In the data collection setup you can define how you want to collect data for your
              material data points. You can choose the data gathering level, frequency and how to
              disaggregate metrics. By default, data points are configured to be collected on the
              company level and yearly, and the disaggregation is not defined. You can use “Bulk
              editing” (below) to configure some metrics disaggregation across all standards at
              once. For other configurations, please, enter each standard individually and follow
              the instruction provided there.
            </Typography>
          </VStack>

          <VStack w="100%" spacing="12px" alignItems="start">
            <HStack justifyContent="space-between" w="100%">
              <Typography variant="h2">Standards to configure</Typography>
              <Button
                variant="secondary"
                size="sm"
                leftIcon={<SettingsIcon color="inherit" />}
                onClick={onModalOpen}
                isDisabled={materialMetrics.length === 0}
              >
                Bulk editing
              </Button>
            </HStack>

            <VStack w="100%" spacing="16px" alignItems="start">
              <ConfigurationBanner
                type="standard"
                ratio={configurationRatio}
                ratioString={configurationRatioString}
              />

              <Table
                columns={columns}
                data={materialStandards}
                onRowClick={(row) => {
                  navigate(`data-collection/${row.standardRef}`);
                }}
                rowProps={{
                  _hover: {
                    cursor: 'pointer',
                    bg: 'bg.hover',
                  },
                }}
                rowIcon={<ArrowNarrowRightIcon />}
                bottomRowBorder={false}
                cellPadding="0px 16px"
                headerPadding="12px 16px"
              />
            </VStack>
          </VStack>
        </VStack>
      ) : (
        <Box my="16px" w="100%" flexGrow="1">
          <EmptyState
            title="Set up your data collection"
            description="There are no material metrics that could be set up available. Assess some metrics as material, and you will be able to configure here how they should be gathered "
            callToAction={{
              text: 'Go to materiality assessment',
              variant: 'secondary',
              onClick: () => (isOnboarding ? setStep?.(3) : onChangeTab?.('materiality')),
            }}
            component={true}
          />
        </Box>
      )}

      <TagBulkEditingModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        materialMetrics={materialMetrics}
        materialStandardsIds={materialStandardsIds}
        loading={groupRequiredTagsLoading || subsidiaryRequiredTagsLoading}
      />
    </ContentLayout>
  );
};
