import { HStack } from '@chakra-ui/react';
import { Tag } from 'Atoms/Tag/Tag.tsx';
import { t } from 'i18next';
import { CompanyIcon } from 'Tokens/Icons/Data.tsx';
import { Typography } from 'Tokens/index.ts';
import { FinancialFilter, useGetFilterData } from './FinancialsHeader.hooks.ts';

export const FinancialsViewForTag = () => {
  const { filter, filteredByName } = useGetFilterData();

  return (
    <HStack width="full" height="64px" justifyContent="space-between">
      <HStack>
        <Typography variant="body">{t('common:words.view')} </Typography>
        {filter === FinancialFilter.byId ? (
          <Tag
            borderRadius="32px"
            padding="8px 16px"
            borderWidth="1px"
            borderColor="border.default"
            bg="bg.default"
          >
            <Typography variant="bodyStrong">{filteredByName}</Typography>
          </Tag>
        ) : (
          <HStack>
            <CompanyIcon />
            <Typography variant="bodyStrong">{filteredByName}</Typography>
          </HStack>
        )}
      </HStack>
    </HStack>
  );
};
