import { HStack, IconButton, Skeleton } from '@chakra-ui/react';
import { NumberInput } from 'Atoms/index.ts';
import { Tooltip } from 'Atoms/Tooltip/Tooltip.tsx';
import { Typography } from 'Tokens/index.ts';
import { useGetFinancialInputData } from './FinancialInput.hooks.ts';
import { useEffect, useState } from 'react';
import { WarningIcon } from 'Tokens/Icons/Status.tsx';

export const FinancialInput = ({
  section,
  financialsId,
}: {
  section: string;
  financialsId: string;
}) => {
  const FINANCIAL_SAVE_DELAY_MS = 1000;

  const [financialNumber, setFinancialNumber] = useState<number>(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [localLatestNumber, setLocalLatestNumber] = useState<number>();

  const { value, errorMessage, isDisabled, isLocked, onChange, loading, resetErrorMessage } =
    useGetFinancialInputData({
      section,
      financialsId,
    });

  useEffect(() => {
    if (errorMessage !== undefined) {
      setFinancialNumber(value);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (initialLoad) {
      if (value !== undefined) {
        setLocalLatestNumber(value);
        setFinancialNumber(value);
        setInitialLoad(false);
      }
    }
  }, [value]);

  useEffect(() => {
    if (financialNumber !== localLatestNumber) {
      const debounceSave = setTimeout(() => {
        setLocalLatestNumber(financialNumber);
        onChange(financialNumber);
      }, FINANCIAL_SAVE_DELAY_MS);

      return () => clearTimeout(debounceSave);
    }
  }, [financialNumber]);

  if (isLocked) {
    return <Typography marginRight="15px">{value}</Typography>;
  }
  return (
    <Tooltip
      backgroundColor="bg.critical.accent"
      placement="top"
      label={errorMessage}
      hasArrow
      defaultIsOpen
      closeDelay={500}
    >
      <Skeleton isLoaded={initialLoad === false}>
        <HStack width="100%">
          <NumberInput
            key={`${financialsId}-${section}`}
            id={`${financialsId}-${section}`}
            width="100%"
            value={financialNumber}
            isInvalid={errorMessage !== undefined}
            isDisabled={isDisabled}
            onChange={(val) => {
              setFinancialNumber(val);
            }}
            onBlur={() => {
              if (financialNumber !== localLatestNumber) {
                onChange(financialNumber);
              }
            }}
            replaceNanWithZero={true}
            loading={loading}
          />
          {errorMessage && (
            <IconButton
              icon={<WarningIcon color="text.warning" />}
              onClick={resetErrorMessage}
              variant="ghost"
              aria-label={''}
            />
          )}
        </HStack>
      </Skeleton>
    </Tooltip>
  );
};
