import { useMaterialStandardId } from 'containers/Esrs';
import {
  GetMetricsDrDocument_,
  EsrsAssessmentDocument_,
  MaterialMetricsPerDisclosureDocument_,
  MaterialMetricsPerDisclosureQuery_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useGetMaterialStandardIsCollectedQuery,
  useMaterialMetricsPerDisclosureQuery,
  useUpsertMaterialMetricsMutation,
  GetRequiredBuMaterialMetricsDocument_,
  GetRequiredMaterialMetricsDocument_,
  useGetRequiredDataCollectionMaterialMetricsQuery,
  useGetMetricTagsDetailForSubsidiariesQuery,
} from 'models';
import { useEffect, useMemo } from 'react';
import {
  DataCollectionLevel,
  MaterialMetricPerDisclosure,
  MetricTagDetailForSubsidiary,
  TableMetricData,
} from './DataCollection.d';
import { uniq } from 'lodash';
import { useParams } from 'react-router-dom';

type Metric =
  MaterialMetricsPerDisclosureQuery_['assessableMetrics'][0]['childrenMetrics'][0]['childMetric'];
type ParentMetric = MaterialMetricsPerDisclosureQuery_['assessableMetrics'][0];

type PartialAssessableMetric = Omit<MaterialMetricPerDisclosure, 'childrenMetrics'>;
export interface MetricPerDisclosureMaybeHasChild extends PartialAssessableMetric {
  childrenMetrics?: { childMetric?: MetricPerDisclosureMaybeHasChild | null }[];
}

export const useMaterialStandardDataCollection = (
  standardRef: string,
  esrsAssessmentId: string
) => {
  const {
    companyAssessmentId: companyStandardId,
    parentAssessmentId: parentStandardId,
    loading: materialStandardIdLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: materialStandardData, loading: materialStandardDataLoading } =
    useGetMaterialStandardIsCollectedQuery({
      variables: {
        assessmentId: esrsAssessmentId,
        standardRef: standardRef,
      },
      skip: !esrsAssessmentId || !standardRef,
    });

  const parentEsrsAssessmentId = useMemo(
    () => materialStandardData?.materialityAssessment[0]?.esrsAssessment.parentAssessment?.id,
    [materialStandardData]
  );

  return {
    companyStandardId,
    parentStandardId,
    parentEsrsAssessmentId,
    loading: materialStandardDataLoading || materialStandardIdLoading,
  };
};

export const useMaterialMetricsPerDR = ({
  disclosureRequirementRef,
  companyStandardId,
  parentStandardId,
  isGroup,
  isStandardMandatory,
}: {
  disclosureRequirementRef: string;
  companyStandardId: string;
  parentStandardId: string;
  isGroup: boolean;
  isStandardMandatory: boolean;
}) => {
  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const { esrsAssessmentId } = useParams();
  const { data: requiredMaterialMetricsData, loading: requiredmmLoading } =
    useGetRequiredDataCollectionMaterialMetricsQuery({
      variables: {
        disclosureRequirementRef: disclosureRequirementRef,
        materialStandardId: companyStandardId,
        parentStandardId: parentStandardId || companyStandardId,
      },
      skip: !disclosureRequirementRef || !companyStandardId,
    });

  const { data: metricTagDetailData, loading: metricTagDetailLoading } =
    useGetMetricTagsDetailForSubsidiariesQuery({
      variables: {
        assessmentId: esrsAssessmentId,
        drRef: disclosureRequirementRef,
      },
      skip: !isGroup,
    });

  const requiredMetrics = useMemo(
    () =>
      uniq(requiredMaterialMetricsData?.esrs_MaterialMetric?.map((mm) => mm.metric.reference)) ??
      [],
    [requiredMaterialMetricsData]
  );

  const tagDetailForSubsidiaries = useMemo(() => {
    const tempTagDetailForSubsidiaries: { [key: string]: MetricTagDetailForSubsidiary } = {};
    metricTagDetailData?.esrs_MaterialMetric?.forEach((met) => {
      if (tempTagDetailForSubsidiaries?.hasOwnProperty(met.metricRef)) {
        tempTagDetailForSubsidiaries[met.metricRef]['subsidiaryDetail'].push({
          companyName: met.materialStandard.esrsAssessment.company.name,
          companyId: met.materialStandard.esrsAssessment.company.id,
          status: {
            text:
              met.metric.tags.length === met.materialMetricTags.length ? 'Configured' : 'Required',
            ratio: `(${met.materialMetricTags.length}/${met.metric.tags.length})`,
          },
          assessmentId: met.materialStandard.esrsAssessment.id,
        });
        tempTagDetailForSubsidiaries[met.metricRef]['configuredCount'] +=
          met.metric.tags.length === met.materialMetricTags.length ? 1 : 0;
      } else {
        tempTagDetailForSubsidiaries[met.metricRef] = {
          subsidiaryDetail: [
            {
              companyName: met.materialStandard.esrsAssessment.company.name,
              companyId: met.materialStandard.esrsAssessment.company.id,
              status: {
                text:
                  met.metric.tags.length === met.materialMetricTags.length
                    ? 'Configured'
                    : 'Required',
                ratio: `(${met.materialMetricTags.length}/${met.metric.tags.length})`,
              },
              assessmentId: met.materialStandard.esrsAssessment.id,
            },
          ],
          configuredCount: met.metric.tags.length === met.materialMetricTags.length ? 1 : 0,
        };
      }
    });
    return tempTagDetailForSubsidiaries;
  }, [metricTagDetailData]);

  const { data: disclosureMetricData, loading: metricDataLoading } =
    useMaterialMetricsPerDisclosureQuery({
      variables: {
        disclosureRequirementRef,
        companyAssessmentId: companyStandardId,
        parentAssessmentId: parentStandardId || companyStandardId,
        requiredMetrics: requiredMetrics,
      },
      skip: !companyStandardId || !disclosureRequirementRef || !requiredMetrics.length,
    });

  const checkIfChildNeedsMaterialMetric = (metric: Metric, parent?: ParentMetric) => {
    const hasMaterialMetric = !!metric?.materialMetrics?.length;
    const defaultDataCollection = isGroup
      ? isStandardMandatory
        ? DataCollectionLevel.group
        : DataCollectionLevel.subsidiaries
      : DataCollectionLevel.company;
    if (!hasMaterialMetric) {
      const parentMaterialMetric = parent?.materialMetrics.find(
        (mm) => mm.materialStandardId === companyStandardId
      );

      // create a material metric for it
      upsertMaterialMetric({
        variables: {
          objects: [
            {
              frequency: parentMaterialMetric?.frequency ?? 'Yearly',
              dataCollection: parentMaterialMetric?.dataCollection ?? defaultDataCollection,
              materialStandardId: companyStandardId,
              metricRef: metric?.reference,
            },
          ],
        },
        refetchQueries: [
          GetMetricsDrDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
          GetRequiredMaterialMetricsDocument_,
          GetRequiredBuMaterialMetricsDocument_,
          MaterialMetricsPerDisclosureDocument_,
          EsrsAssessmentDocument_,
        ],
      }).catch((error) => {
        console.log('Failed to add material metric for missing child', error);
      });
    }
    if (!!metric?.childrenMetrics?.length) {
      metric?.childrenMetrics.forEach((childMetric) => {
        checkIfChildNeedsMaterialMetric(childMetric.childMetric as Metric, metric as ParentMetric);
      });
    }
  };

  useEffect(() => {
    disclosureMetricData?.assessableMetrics.forEach((metric) => {
      checkIfChildNeedsMaterialMetric(metric as Metric);
    });
  }, [disclosureMetricData]);

  return {
    disclosureMetricData,
    requiredMetrics,
    tagDetailForSubsidiaries,
    metricDataLoading: metricDataLoading || requiredmmLoading || metricTagDetailLoading,
  };
};

export const getAllMaterialMetricChildren = (
  companyStandardId: string,
  metric?: TableMetricData
): any => {
  const childrenMetrics = metric?.childrenMetrics;

  if (!childrenMetrics) {
    return [];
  }

  const data = childrenMetrics.flatMap((childMetric) => {
    const childData = getAllMaterialMetricChildren(
      companyStandardId,
      childMetric?.childMetric as TableMetricData
    );
    const materialMetric = childMetric.childMetric?.materialMetrics.find(
      (mm) => mm.materialStandardId === companyStandardId
    );
    return [
      {
        id: materialMetric?.id,
        metricRef: childMetric.childMetric?.reference,
        materialStandardId: companyStandardId,
        metricType: childMetric.childMetric?.metricType,
        isMaterial: materialMetric?.isMaterial,
      },
      ...childData,
    ];
  });

  return data;
};

export const getFlattenedMaterialMetrics = (
  metrics: TableMetricData[],
  companyStandardId: string
): any => {
  return metrics
    .flatMap((metric) => {
      const materialMetric = metric.materialMetrics.find(
        (mm) => mm.materialStandardId === companyStandardId
      );
      return [
        {
          id: materialMetric?.id,
          metricRef: metric.reference,
          materialStandardId: companyStandardId,
          metricType: metric.metricType,
          isMaterial: materialMetric?.isMaterial,
        },
        getAllMaterialMetricChildren(companyStandardId, metric),
      ].flat();
    })
    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
};

export const hasDataCollectionGroup = (metric: TableMetricData): boolean => {
  const isCollectionGroup = metric.materialMetrics?.[0]?.dataCollection === 'group';
  if (!metric?.childrenMetrics?.length) return isCollectionGroup;
  return (
    isCollectionGroup ||
    metric.childrenMetrics.some(
      (child) => child.childMetric && hasDataCollectionGroup(child.childMetric as TableMetricData)
    )
  );
};

export const metricsWithRequiredTags = (
  metricList: MaterialMetricPerDisclosure[],
  isGroup: boolean
): MaterialMetricPerDisclosure[] => {
  const visitedMetric: { [key: string]: boolean } = {};
  const requiredMetricList: MaterialMetricPerDisclosure[] = [];
  const addMetricToList = (metric: MaterialMetricPerDisclosure): void => {
    if (visitedMetric.hasOwnProperty(metric?.reference)) {
      return;
    }
    visitedMetric[metric?.reference] = true;
    const tagList = uniq([
      ...metric?.adminPanelTags,
      ...metric?.materialMetrics?.[0]?.materialMetricTags,
    ]);
    if (!tagList.length) {
      if (metric?.childrenMetrics?.length) {
        metric.childrenMetrics.forEach(
          (met) => met.childMetric && addMetricToList(met.childMetric)
        );
      }
      return;
    }
    if (tagList.some((tag) => !tag.isOptional)) {
      if (metric?.childrenMetrics?.length) {
        const dataCollection =
          metric.childrenMetrics[0]?.childMetric?.materialMetrics?.[0]?.dataCollection;
        if (!isGroup || dataCollection === 'group') {
          requiredMetricList.push(metric);
        }
        return;
      }
      const dataCollection = metric?.materialMetrics?.[0]?.dataCollection;
      if (!isGroup || dataCollection === 'group') {
        requiredMetricList.push(metric);
      }
    }
  };

  metricList.forEach((met) => addMetricToList(met));
  return requiredMetricList;
};

export const hasCompletedTags = (
  metric: MetricPerDisclosureMaybeHasChild,
  isRequired?: boolean
): boolean => {
  const tags = metric.adminPanelTags.filter((tag) => (isRequired ? !tag.isOptional : true));

  const hasCompletedAllTags = metric.materialMetrics.some((mm) =>
    tags.every((tag) =>
      mm.materialMetricTags.some(
        (matTag) => matTag.tagType === tag.type && matTag.materialTagValues.length > 0
      )
    )
  );

  if (!metric.childrenMetrics?.length) return hasCompletedAllTags;

  return (
    hasCompletedAllTags &&
    metric.childrenMetrics.every(
      (child) => child.childMetric && hasCompletedTags(child.childMetric, isRequired)
    )
  );
};
