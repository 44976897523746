import { ContentLayout } from 'Molecules/ContentLayout/ContentLayout.tsx';
import { FinancialsTablesPageHeader } from './Header/FinancialsTablesPageHeader.tsx';
import { VStack } from '@chakra-ui/react';
import { FilteredFinancials } from './Tables/FilteredFinancials.tsx';

export const FinancialsTablesPage = () => {
  return (
    <ContentLayout variant="inline" header={null}>
      <VStack width="100%" justifyContent="flex-start" paddingY="0px" pr="16px">
        <FinancialsTablesPageHeader />
        <FilteredFinancials />
      </VStack>
    </ContentLayout>
  );
};
