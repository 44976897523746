export const ARTICLE_SELECT_ACTIVITY = {
  slug: 'select-activity',
  title: 'How do I select the correct activity?',
};

export const ARTICLE_TIMELINE_REPORTING = {
  slug: 'timeline-reporting',
  title: 'Timeline for reporting and taxonomy updates',
};

export const ARTICLE_WHAT_IS_TAXONOMY = {
  slug: 'what-is-taxonomy',
  title: 'What is the EU Taxonomy?',
};

export const ARTICLE_GREEN_DEAL = {
  slug: 'green-deal',
  title: 'What is the EU Green Deal?',
};

export const ARTICLE_WHAT_IS_TURNOVER = {
  slug: 'what-is-turnover',
  title: 'What is turnover?',
};

export const ARTICLE_ACTIVITIES_STRUCTURE = {
  slug: 'activities-structure',
  title: 'How to choose activities and structure in reporting units',
};

export const ARTICLE_WHY_DO_AN_ASSESSMENT = {
  slug: 'why-do-an-assessment',
  title: 'Why should I do a taxonomy assessment?',
};

export const ARTICLE_WHAT_DOCUMENTATION = {
  slug: 'what-documentation',
  title: 'What documentation should be provided?',
};

export const ARTICLE_ADAPTATION_KPIS = {
  slug: 'adaptation-kpis',
  title: 'Adaptation activities: how should the KPIs be calculated for adaptation?',
};

export const ARTICLE_WHAT_IS_CAPEX = {
  slug: 'what-is-capex',
  title: 'What is CapEx?',
};

export const ARTICLE_WHAT_IS_OPEX = {
  slug: 'what-is-opex',
  title: 'What is OpEx?',
};

export const ARTICLE_PAI_INDICATORS = {
  slug: 'pai-indicators',
  title: 'What are PAI indicators',
};

export const ARTICLE_INTRO_CLIMATERISK = {
  slug: 'intro-climaterisk',
  title: 'Introduction to climate risk',
};

export const ARTICLE_SIX_STEPS = {
  slug: 'six-steps',
  title: 'Taxonomy assessment in 6 steps with Celsia',
};

export const ARTICLE_ABOUT_CELSIA = {
  slug: 'about-celsia',
  title: 'About Celsia',
};

export const ARTICLE_ENVIRONMENTAL_IMPACT_ASSESSMENT = {
  slug: 'environmental-impact-assessment',
  title: 'Environmental impact assessment',
};

export const ARTICLE_BAT_AEL = {
  slug: 'bat-ael',
  title: 'BAT-AEL',
};

export const ARTICLE_GOOD_ENVIRONMENTAL_STATUS = {
  slug: 'good-environmental-status',
  title: 'Good environmental status',
};

export const ARTICLE_TAXONOMY_NORWAY = {
  slug: 'taxonomy-norway',
  title: 'How does the EU Taxonomy apply in Norway?',
};

export const ARTICLE_INTERPRET_REQUIREMENTS = {
  slug: 'interpret-requirements',
  title: 'How should taxonomy requirements be interpreted?',
};

export const ARTICLE_CLIMATE_RISK_ASSESSMENT = {
  slug: 'climate-risk-assessment',
  title: 'Climate Risk Assessment',
};

export const ARTICLE_ADAPTATION_ENABLING = {
  slug: 'adaptation-enabling',
  title: 'What are adaptation and enabling adaptation activities?',
};

export const ARTICLE_ENABLING_TRANSITIONAL = {
  slug: 'enabling-transitional',
  title: 'What are enabling and transitional activities?',
};

export const ARTICLE_ADAPTATION_CAPEX_OPEX = {
  slug: 'adaptation-capex-opex',
  title: 'What is adaptation OpEx and CapEx?',
};

export const ARTICLE_NEW_EIA = {
  slug: 'new-EIA',
  title: 'What is an Environmental Impact Assessment (EIA)?',
};

export const ARTICLE_WASTE_HIERARCHY = {
  slug: 'waste-hierarchy',
  title: 'Waste hierarchy',
};

export const ARTICLE_LIFE_CYCLE_GHG_EMISSIONS = {
  slug: 'life-cycle-ghg-emissions',
  title: 'Life-cycle GHG emissions',
};

export const ARTICLE_RECYCLING_REQUIREMENTS = {
  slug: 'recycling-requirements',
  title: 'Recycling requirements for vehicles',
};

export const ARTICLE_VEHICLE_CATEGORIES = {
  slug: 'vehicle-categories',
  title: 'Vehicle categories',
};

export const ARTICLE_CSRD_RESOURCES = {
  slug: 'csrd-resources',
  title: 'Corporate Sustainability Reporting Directive and Resources',
};

export const ARTICLE_TAXONOMY_RESOURCES = {
  slug: 'taxonomy-resources',
  title: 'EU Taxonomy Regulation and Resources',
};

export const ARTICLE_TRANSPARENCY_ACT = {
  slug: 'transparency-act',
  title: 'Norwegian Transparency Act / Åpenhetsloven',
};

export const ARTICLE_SFDR_RESOURCES = {
  slug: 'sfdr-resources',
  title: 'Sustainable Finance Disclosure Regulation and Resources',
};

export const ARTICLE_HOW_TO_REPORT = {
  slug: 'how-to-report',
  title: 'How to report on the EU Taxonomy for non-financial companies',
};

export const ARTICLE_APPENDIX_C = {
  slug: 'appendix-c',
  title: 'How to assess Appendix C criteria for pollution prevention',
};

export const ARTICLE_HOW_TO_CALCULATE_GHG_EMISSIONS = {
  slug: 'How to calculate GHG emissions?',
  title: 'How to calculate GHG emissions?',
};

export const ARTICLE_CONSOLIDATE_FINANCIALS = {
  slug: 'consolidate-financials',
  title: 'How to consolidate the taxonomy financials for group companies',
};

export const ARTICLE_REPORTING_UNIT = {
  slug: 'reporting-unit',
  title: 'What is a reporting unit?',
};

export const ARTICLE_CRA_ACTIVITY = {
  slug: 'cra-activity',
  title: 'Conducting climate risk assessment on an activity level',
};

export const ARTICLE_EURO_VI_STANDARD = {
  slug: 'euro-vi-standard',
  title: 'EURO VI standard',
};

export const ARTICLE_PROHIBITED_CHEMICAL_SUBSTANCES = {
  slug: 'prohibited-chemical-substances',
  title: 'Prohibited chemical substances',
};

export const ARTICLE_NA_ANSWERS = {
  slug: 'na-answers',
  title: 'How should the N/A answer option be used?',
};

export const ARTICLE_WHAT_IS_AN_ACTIVITY = {
  slug: 'what-is-an-activity',
  title: 'What is an activity?',
};

export const ARTICLE_ENABLING_TRANSITIONAL_EXCLUSIONS = {
  slug: 'enabling-transitional-exclusions',
  title:
    'How to report on enabling/transitional taxonomy activities that meet the exclusion criteria?',
};
