import {
  HStack,
  IconButton,
  Avatar,
  VStack,
  Skeleton,
  Collapse,
  Box,
  MenuButton,
  StackProps,
} from '@chakra-ui/react';
import { NodeModel, RenderParams, DragOverProps, useDragOver } from '@minoru/react-dnd-treeview';
import { ProgressBar, TruncatableText, Button, VisibleIf } from 'Atoms';
import { Typography } from 'Tokens';
import { ReportingGroup, BAssessment, CompanyDetails, CompanyAssessment } from 'models';
import { UserAvatar } from 'Organisms';
import React, { useState } from 'react';
import { Menu, MenuSection } from 'Molecules/Menu';
import { AlignmentStatus, AlignmentStatusItem } from 'Organisms/AlignmentStatus';
import { useTranslation } from 'utils/translation';
import { CompanyIcon, FolderIcon } from 'Tokens/Icons/Data';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';

export enum NodeType {
  reportingGroup = 'reportingGroup',
  businessUnit = 'businessUnit',
  company = 'company',
}

export type NodeItem = NodeModel<{
  type: NodeType;
  original?: Partial<ReportingGroup> & Partial<BAssessment>;
}>;

type NodeItemProps = {
  node: NodeItem;
  state?: RenderParams;
  sections: MenuSection[];
  company: CompanyDetails;
  isSelected: boolean;
  isLocked: boolean;
  activeDragging?: boolean;
  childrenCount?: number;
  onClick: () => void;
  progress?: number;
  withProgress?: boolean;
  selectedParents?: string[];
  setSelectedParents?: (newVal: string[]) => void;
  alignmentStatuses?: AlignmentStatusItem[];
  isDataLoading?: boolean;
};

export const NodeBox = React.forwardRef(
  (
    {
      isGroup,
      depth,
      isDragging,
      isSelected,
      children,
      activeDragging,
      onClick,
      state,
      node,
      selectedParents,
      withProgress,
      isGroupEmptyVisible,
      ...rest
    }: {
      isGroup: boolean;
      depth: number;
      isSelected: boolean;
      isDragging: boolean;
      children: React.ReactNode | React.ReactNode[];
      activeDragging?: boolean;
      onClick: () => void;
      state?: RenderParams | undefined;
      node?: NodeItem;
      selectedParents?: string[];
      withProgress?: boolean;
      isGroupEmptyVisible?: boolean;
    } & Partial<DragOverProps> &
      StackProps,
    ref: React.LegacyRef<HTMLDivElement>
  ) => {
    const getDepthPadding = () => {
      if (isGroup) {
        return `${depth * 16 - 16}px`;
      }
      return `${depth * 16 + 16}px`;
    };
    return (
      <HStack
        {...rest}
        onClick={onClick}
        className="tree-node"
        ref={ref}
        width="319px"
        height={withProgress ? (isGroupEmptyVisible ? '216px' : '108px') : ''}
        paddingLeft={getDepthPadding()}
        bg="bg.default"
        paddingTop="16px"
        paddingBottom={
          withProgress ? (isGroupEmptyVisible ? '16px' : '0px') : isGroupEmptyVisible ? '' : '16px'
        }
        paddingRight={withProgress ? (isGroupEmptyVisible ? '0px' : '16px') : ''}
        spacing="8px"
        sx={{
          bg: 'bg.default',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'border.decorative',
          _hover: {
            bg: 'bg.hover',
            cursor: 'pointer',
          },
          _active: {
            bg: 'bg.pressed',
            cursor: 'grabbing',
          },
          ...(isSelected
            ? {
                bg: 'bg.selected',
                _hover: {
                  bg: 'bg.selected.hover',
                  cursor: 'pointer',
                },
              }
            : {}),
          ...(state?.isOpen && isGroup
            ? {
                bg: 'bg.selected.muted',
              }
            : {}),
          ...(selectedParents?.includes(node?.data?.original?.reportingGroup?.id) ||
          selectedParents?.includes(node?.data?.original?.parentId)
            ? isSelected
              ? { bg: 'bg.selected' }
              : {
                  bg: 'bg.muted',
                }
            : {}),
        }}
      >
        {children}
      </HStack>
    );
  }
);

export const NodeItemBox = React.forwardRef(
  (
    {
      node,
      state,
      company,
      isLocked,
      sections,
      isSelected,
      activeDragging,
      childrenCount,
      onClick,
      progress,
      withProgress = false,
      selectedParents,
      setSelectedParents,
      alignmentStatuses = [],
      isDataLoading,
    }: NodeItemProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { type, original } = node.data ?? {};
    const { depth, isOpen, onToggle, isDragging } = state ?? {
      depth: 0,
      isOpen: false,
      onToggle: () => null,
      isDragging: false,
    };

    const { t } = useTranslation(['bUnits', 'common']);
    const correctDepth = depth + 1;
    const [isVisible, setIsVisible] = useState(true);
    const isReportingGroup = type === NodeType.reportingGroup;
    const isGroupEmptyVisible = isReportingGroup && childrenCount === 0 && !isVisible;
    const contactPerson = isReportingGroup
      ? (original as ReportingGroup).owner
      : (original as BAssessment).businessUnit?.contactPerson;
    const dragOverProps = useDragOver(node.id, isOpen, onToggle);

    return (
      <NodeBox
        isGroup={isReportingGroup}
        isSelected={isSelected}
        depth={correctDepth}
        isDragging={isDragging}
        activeDragging={activeDragging}
        ref={ref}
        state={state}
        node={node}
        isGroupEmptyVisible={isGroupEmptyVisible}
        onClick={() => {
          onClick();
          state?.onToggle();
          if (isReportingGroup) {
            if (selectedParents?.includes(String(node.id))) {
              setSelectedParents?.(selectedParents.filter((i) => i !== node.id));
            } else setSelectedParents?.([...(selectedParents ?? []), String(node.id)]);
            setIsVisible(!isVisible);
          }
        }}
        selectedParents={selectedParents}
        withProgress={withProgress}
        alignItems={withProgress && isReportingGroup ? 'flex-start' : undefined}
        {...dragOverProps}
      >
        <VStack width="100%" alignItems="stretch">
          <VStack
            width="100%"
            paddingRight={withProgress ? (isGroupEmptyVisible ? '16px' : '0px') : ''}
            alignItems="stretch"
          >
            <HStack width="100%" justifyContent="space-between">
              {state && isReportingGroup && (
                <IconButton
                  size="sm"
                  aria-label="expand"
                  variant="ghost"
                  isDisabled={childrenCount === 0}
                  onClick={state?.onToggle}
                  icon={
                    state?.isOpen ? (
                      <ChevronDownIcon color="inherit" />
                    ) : (
                      <ChevronRightIcon color="inherit" />
                    )
                  }
                  marginLeft="5px"
                />
              )}
              <HStack width="100%">
                {isReportingGroup && <FolderIcon boxSize="13px" />}
                <TruncatableText
                  text={(isReportingGroup ? original?.name : original?.businessUnit?.name) ?? ''}
                  variant={isReportingGroup ? 'h4' : 'body'}
                />
                {isReportingGroup && (
                  <Typography variant="detail" color="text.muted">
                    {childrenCount}
                  </Typography>
                )}
              </HStack>
              <HStack spacing="4px">
                {contactPerson ? (
                  <UserAvatar user={contactPerson} size="sm" />
                ) : (
                  <Avatar name={company.name} size="sm" />
                )}
                <VisibleIf condition={!isLocked}>
                  <Menu key={node.id + 'menu'} sections={sections} size="lg" />
                </VisibleIf>
              </HStack>
            </HStack>
            <Collapse in={withProgress}>
              <Skeleton
                isLoaded={progress !== null && progress !== undefined && !isDataLoading}
                width="100%"
              >
                <HStack width="100%" justifyContent="stretch" paddingRight="4px">
                  <ProgressBar completed={progress ?? 0} isGroup={isReportingGroup} />
                </HStack>
              </Skeleton>
            </Collapse>
            {alignmentStatuses?.length && (
              <HStack width="100%" spacing="8px">
                {alignmentStatuses?.map((status) => (
                  <Skeleton isLoaded={!isDataLoading} key={status?.type}>
                    <AlignmentStatus
                      key={status?.type}
                      type={status?.type}
                      status={status.status}
                      isInherited={status.isInherited}
                    />
                  </Skeleton>
                ))}
              </HStack>
            )}
          </VStack>
          {isGroupEmptyVisible ? (
            <VStack width="100%">
              <Box
                width="100%"
                height={withProgress ? '108px' : ''}
                bg="bg.muted"
                borderTop="1px"
                borderTopStyle="solid"
                borderTopColor="border.decorative"
                mt={withProgress ? '36px' : '8px'}
                paddingBottom="16px"
              >
                <Typography variant="body" color="text.muted" mt="12px" ml="32px">
                  {t('bUnits:emptyGroup')}
                </Typography>
                <Box onClick={(e) => e.stopPropagation()} ml="32px" mt="8px">
                  <Menu
                    menuButton={
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon color="inherit" />}
                        size="sm"
                      >
                        {t('common:actions.add')}
                      </MenuButton>
                    }
                    sections={[sections[0]]}
                    size="lg"
                  />
                </Box>
              </Box>
            </VStack>
          ) : (
            <></>
          )}
        </VStack>
      </NodeBox>
    );
  }
);

export const CompanyRootNode = ({
  company,
  childrenCount,
  isSelected,
  cAssessment,
  sections,
  onClick,
  progress,
  withProgress = false,
  alignmentStatuses = [],
  isDataLoading,
}: {
  company: CompanyDetails;
  cAssessment: CompanyAssessment;
  childrenCount: number;
  isSelected: boolean;
  sections: MenuSection[];
  onClick: () => void;
  progress?: number;
  withProgress?: boolean;
  alignmentStatuses?: AlignmentStatusItem[];
  isDataLoading?: boolean;
}) => {
  return (
    <NodeBox
      isGroup
      depth={2}
      isDragging={false}
      isSelected={isSelected}
      onClick={onClick}
      withProgress={withProgress}
    >
      <VStack width="100%" alignItems="stretch">
        <HStack width="100%" justifyContent="space-between">
          <HStack width="100%">
            <CompanyIcon />
            <TruncatableText variant="h4" text={company.name} />
            <Typography variant="detail" color="text.muted">
              {childrenCount}
            </Typography>
          </HStack>
          <HStack spacing="4px">
            {cAssessment?.aggregate?.contactPerson ? (
              <UserAvatar user={cAssessment?.aggregate?.contactPerson} size="sm" />
            ) : (
              <Avatar name={company.name} size="sm" />
            )}
            <VisibleIf condition={!cAssessment?.isLocked}>
              <Menu sections={sections} size="lg" />
            </VisibleIf>
          </HStack>
        </HStack>
        <Collapse in={withProgress}>
          <Skeleton isLoaded={progress !== undefined && !isDataLoading} width="100%">
            <HStack width="100%" justifyContent="stretch" paddingRight="4px">
              <ProgressBar completed={progress ?? 0} />
            </HStack>
          </Skeleton>
        </Collapse>
        {alignmentStatuses.length && (
          <HStack width="100%" spacing="8px">
            {alignmentStatuses?.map((status) => (
              <Skeleton isLoaded={!isDataLoading} key={status?.type}>
                <AlignmentStatus
                  key={status?.type}
                  type={status?.type}
                  status={status.status}
                  isInherited={status.isInherited}
                />
              </Skeleton>
            ))}
          </HStack>
        )}
      </VStack>
    </NodeBox>
  );
};
